import React from 'react'
import {
    TableRow,
    TableHeaderCell,
    TableHeader,
    TableCell,
    TableBody,
    Table,
    Label,
} from 'semantic-ui-react'
import { TaskStatus } from './TasksEnum';
const _ = require('underscore');

interface props {
    tasks: any[]
}
const AwsDbMigrationTable = (props: props) => {
    const { tasks } = props;
    const sortedTasks = _.sortBy((tasks || []), function (task: any) {
        if (task.derivedStatus === 'Failed') return 1;
        if (task.derivedStatus === 'Load Complete') return 2;
        if (task.derivedStatus === 'Stopped') return 3;
        if (task.derivedStatus === 'Load Complete, Replication Ongoing') return 4;
        return 5;
    });

    const setStatus = (status: string) => {
        switch (status) {
            case TaskStatus.Load_Complete:
                return <Label basic color='green'>Load complete</Label>;
            case TaskStatus.Stopped:
                return <Label basic color='red'>Stopped</Label>;
            case TaskStatus.Load_Complete_Replication_Ongoing:
                return <Label basic color='green'>Load complete, replication ongoing</Label>;
            case TaskStatus.Failed:
                return <Label basic color='red'>Failed</Label>;
            default:
                return <Label basic color='grey'>{status}</Label>;
        }
    };

    const setType = (type: String) => {
        return type === 'full-load' ? 'Full Load' : 'Full load, ongoing replication';
    }

    return (
        <>
            <Table striped compact='very'>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Practice</TableHeaderCell>
                        <TableHeaderCell>Identifier</TableHeaderCell>
                        <TableHeaderCell>Status</TableHeaderCell>
                        <TableHeaderCell>Type</TableHeaderCell>
                        <TableHeaderCell>Tables Loaded</TableHeaderCell>
                        <TableHeaderCell>Tables Loading</TableHeaderCell>
                        <TableHeaderCell>Tables Queued</TableHeaderCell>
                        <TableHeaderCell>Tables Errored</TableHeaderCell>

                    </TableRow>
                </TableHeader>

                <TableBody>
                    {
                        sortedTasks.map((task: any, index: number) =>
                            <TableRow key={index}>
                                <TableCell>{task?.practice || '-'}</TableCell>
                                <TableCell>{task.ReplicationTaskIdentifier}</TableCell>
                                <TableCell>{setStatus(task.derivedStatus)}</TableCell>
                                <TableCell>{setType(task.MigrationType)}</TableCell>
                                <TableCell>{task.ReplicationTaskStats.TablesLoaded}</TableCell>
                                <TableCell>{task.ReplicationTaskStats.TablesLoading}</TableCell>
                                <TableCell>{task.ReplicationTaskStats.TablesQueued}</TableCell>
                                <TableCell>{task.ReplicationTaskStats.TablesErrored}</TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </>

    )
}

export default AwsDbMigrationTable