import React from "react";
import Template from "./Template";
import {RootState} from "../model";
import {connect} from "react-redux";
import PatientValidationInfoForm from "./PatientValidationForm/PatientValidationInfoForm";
import {findPractice} from "../utils/common";
import {Account} from "../model/user";
import {Practice} from "../model/practice";
import {Patients} from "../model/patients";

interface Props {
    user: Account,
    practice: Practice,
    patients: Patients,
    loginVia: String,
}
function PatientValidation(props: Props) {
    const { patients,  practice } = props

    const getDefaultPractices = () => {
        const practiceObj:any = patients.defaultPractice;
        let defaultPractices: string[] = [];
         if (practiceObj && practiceObj.value !== 'all') {
            const practiceDbNameList = verifyDefaultPractice(practiceObj.value);
            if (practiceDbNameList.length > 0) {
                practiceDbNameList.forEach((prac: string) => {
                    if (practice.practiceList !== undefined) {
                        const practiceObj = findPractice(practice.practiceList, prac) || {practice: 'all'};
                        defaultPractices.push(practiceObj.practice);
                    } else {
                        defaultPractices.push(prac);
                    }
                });
            }
        } else if (practiceObj && practiceObj.value === 'all') {
            defaultPractices = ['all'];
        }
        return defaultPractices;
    }

    const verifyDefaultPractice = (practice: string) => {
        const str = practice;
        const arr = str.split(",");
        if (arr.length > 1) {
            const index = str.split(",").indexOf("all");
            if (index > -1) {
                return str.split(",").filter(function (value, index, arr) {
                    return value !== 'all';
                });
            }
        }
        return str.split(",");
    }

    return (
        <Template activeLink='patient-validation'>
            <PatientValidationInfoForm key="main-patient-validation" getDefaultPractices={getDefaultPractices}/>
        </Template>
    );

}

function mapStateToProps(state: RootState) {
    return {
        user: state.auth.user,
        practice: state.practice,
        patients: state.patients,
        loginVia: state.report.loginVia,
        providerAccountList: state.providerAccountList,
    };
}

export default connect(mapStateToProps)(PatientValidation);
