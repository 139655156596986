
import { ActionType } from "../../model/actionTypes";
import { HealthFails, HealthScore } from "../../model/healthScore";
import { PatientFilters } from "../../model/patients";
import { errorHandler } from "./auth";


export const getCronsHealthStatus = (title: string) => async (dispatch: Function, getState: Function, api: any) => {

    try {
        let query = '';
        if (title) {
            query = `?title=${title}`;
        }
        const resp = await api.get(`health-status/track-crons${query}`);
        return resp;
    } catch (err) {
        return dispatch(errorHandler(err));
    }
};

export const getDentalHubHealth = () => async (dispatch: Function, getState: Function, api: any) => {

    try {
        const resp = await api.get("health-status/dental-hub");
        return resp;
    } catch (err) {
        return dispatch(errorHandler(err));
    }
};

export const getPasswordCheckerHealth = () => async (dispatch: Function, getState: Function, api: any) => {

    try {
        const resp = await api.get("health-status/passwordChecker");
        return resp;
    } catch (err) {
        return dispatch(errorHandler(err));
    }
};

export const getAptoodConfigHealth = () => async (dispatch: Function, getState: Function, api: any) => {

    try {
        const resp = await api.get("health-status/openDentalApiConfigs");
        return resp;
    } catch (err) {
        return dispatch(errorHandler(err));
    }
};

export const getSpotHealth = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const resp = await api.get("health-status/spot");
        return resp;
    } catch (err) {
        return dispatch(errorHandler(err));
    }
};

export const getOpenDentalConnection = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const resp = await api.get("health-status/open-dental-connections");
        return resp.map( (r:any) => ({ ...r , ...r.apiConfig.practice}));
    } catch (err) {
        return dispatch(errorHandler(err));
    }
};

export const get = (endpoint: string) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const resp = await api.get(endpoint);
        return resp;
    } catch (err) {
        return dispatch(errorHandler(err));
    }
};

export const addToCount = (healthScore: HealthScore, failed?: HealthFails) => async (dispatch: Function, getState: Function, api: any) => {
    const failedListing = { name: failed?.tab, description: failed?.items?.map((o: any) => o[failed?.mapBy]).join(' , ')};
    try {
        dispatch({ type: ActionType.HEALTH_STATUS_ADD_TO_COUNT, payload: healthScore });
        dispatch({ type: ActionType.HEALTH_FAILS, payload: failedListing });
        return 0;
    } catch (err) {
        console.log(err)
        dispatch({ type: ActionType.HEALTH_STATUS_ADD_TO_COUNT, payload: healthScore });
        dispatch({ type: ActionType.HEALTH_FAILS, payload: failedListing });
        return err;
    }
};

export const getPatientValidation = (params: PatientFilters) => async (dispatch: Function, getState: Function, api: any) => {
    let stringifyParams;
    if (params) {
        stringifyParams = '/' + JSON.stringify(params)
    }
    try {
        const resp = await api.get("patientValidation/getPatients" + stringifyParams);
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const getCarrierIdentity = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const resp = await api.get("patientValidation/carrierIdentity");
        dispatch({ type: ActionType.CARRIER_IDENTITY, payload: { carrierIdentity: resp } })
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: [] });
        return dispatch(errorHandler(err));
    }
}

export const getPatientValHealth = (params: PatientFilters) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const response = await api.get("health-status/patient-validation");
        dispatch({ type: ActionType.HEALTH_STATUS_PATIENT_VALIDATION, payload: response })
        return response;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: [] });
        return dispatch(errorHandler(err));
    }
}

export const manuallyRerunLambdaFunction =
    () => async (dispatch: Function, _getState: Function, api: any) => {
        try {
            return await api.get(`lambdas/manuallyRerunLambdaFunction`);
        } catch (error) {
        }
    };

export const emailSupport = (tickets: any[] , email: string) => async (dispatch: Function, _getState: Function, api: any) => {
    try {
        const resp = await api.post("health-status/send-email", { healthItems: tickets , user : email });
        return resp;
    } catch (error) {
    }
};
