import React, { useState } from 'react';
import Template from "../../components/Template";
import { Accordion, Card, Grid, GridColumn, Icon, Label } from "semantic-ui-react";

import { RootState } from "../../model";
import { User } from "../../model/user";
import { connect, useSelector } from "react-redux";
import CronsHealth from './CronsHealth';
import DentaHubChart from './DentaHubChart';
import DentaHubChartByMco from './DentaHubChatByMco';
import PasswordCheckerHealth from './PasswordCheckerHealth';
import DentaHub from './DentaHub'
import AptoodConfigHealth from './AptoodConfigHealth';
import DentaHubApps from './DentaHubApps';
import SpotHealth from './Spot/SpotHealth';
import OrthoMountDateHealth from './OrthoMountDate/OrthoMountDateHealth';
import XrayServiceHealth from './XrayService/XrayServiceHealth';
import OpenDentalHealth from './ODConnection/OpenDentalStatusHealth';
import DentalXChangeHealth from './DentalXChange/DentalXChangeHealth';
import Five9CallsHealth from "./Five9Calls/Five9CallsHealth";
import AwsDatabaseMigration from './AWSDataMigration/AwsDatabaseMigration';
import PatientValidationHealth from './PatientValidation/PatientValidationHealth';
import HalopsaHealth from './Halopsa/HalopsaHealth';
import MySQLHealth from './MySQL/MySQLHealth';
import AwsBounceEmails from './AwsBounceEmail/AwsBounce';
import moment from 'moment';
import AwsLambdaHealth from './AwsLambdas/LambdaHealth';
import EmailModal from './emailModal';

interface Props {
    auth: User
}

function HealthStatus(props: Props) {
    const [state, setState] = useState({
        viewMail: false,
        data: { groupedData: [], records: [], trackerRecords: [] }, loading: false, count: {
            success: 0,
            failed: 0,
            pending: 0,
        }
    });

    const { data, loading, count, viewMail } = state;
    const states: any = useSelector((state) => state);

    const getTimeStamp = () => {
        const date = moment().format('YYYY-MM-DD HH:mm');
        return <strong style={{ color: 'gray' }}><small>{date}</small></strong>
    }


    return (
        <Template activeLink='health status'>
            {viewMail && <EmailModal open={viewMail} close={() => setState({ ...state, viewMail: false })} />}
            <Card fluid style={{ padding: '10px' }}  >
                <Grid>
                    <GridColumn floated='left' width={3}>
                        <h2 className="float-left mr10">Health Status <br />
                            {getTimeStamp()}
                        </h2>
                        <Label as='a' size='large' onClick={() => setState({ ...state, viewMail: true })} color='grey'>
                            <Icon name='mail outline' /> Email support
                        </Label>
                    </GridColumn>
                        <GridColumn floated='right' textAlign='right' width={12}>
                        <Label size='huge' color='green' >
                            {states.healthScore.success}
                        </Label>
                        <Label size='huge' color='yellow' >
                            {states.healthScore.pending}
                        </Label>
                        <Label size='huge' color='red' >
                            {states.healthScore.failed}
                        </Label>
                    </GridColumn>
                </Grid>
            </Card>
            <Grid.Row>
                <Accordion styled fluid>
                    <DentaHub data={data} auth={props.auth} overalCount={count} />
                    <PatientValidationHealth />
                    <DentaHubChartByMco data={data} overalCount={count} loading={loading} />
                    <DentaHubApps data={data} overalCount={count} loading={loading} />
                    <DentaHubChart
                        setDentaData={result => setState({ ...state, data: result })}
                        setCount={(count: any) => setState({ ...state, count: count })}
                        setLoader={loading => setState(prevState => ({ ...prevState, loading }))} />
                    <DentalXChangeHealth data={data} loading={loading} />
                    <SpotHealth />
                    <OpenDentalHealth />
                    <HalopsaHealth />
                    <PasswordCheckerHealth />
                    <AptoodConfigHealth />
                    <XrayServiceHealth />
                    <MySQLHealth />
                    <AwsLambdaHealth />
                    <AwsDatabaseMigration />
                    <AwsBounceEmails />
                    <OrthoMountDateHealth />
                    <CronsHealth />
                    <Five9CallsHealth />
                </Accordion>
            </Grid.Row>

        </Template>
    );
}

function mapStateToProps(state: RootState) {
    return {
        auth: state.auth,
    };
}

export default connect(mapStateToProps)(HealthStatus)