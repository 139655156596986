import React, { useEffect, useState } from 'react';
import { useActions } from '../../../redux/actions';
import * as HealthStatusActions from "../../../redux/actions/healthStatus";
import { AccordionTitle, AccordionContent, Icon, Grid, GridRow, GridColumn, Loader, Button, ButtonGroup } from 'semantic-ui-react'
import SpotHealthTable from './SpotHealthTable';
import ProgressBar from '../ProgressBar';
import { TABS } from '../healthStatusUtils';


function SpotHealth() {
    const initialState = { panel: '', loading: false, data: [], response: [], counts: { failed: 0, success: 0, pending: 0 } };
    const [state, setState] = useState(initialState);
    const healthStatusActions = useActions(HealthStatusActions);
    const prefix = TABS.SPOT_HEALTH;
    const { panel, loading, response, counts } = state;

    const onSelect = (selected: string) => {
        if (panel === selected)
            setState({ ...state, panel: '' });
        else
            setState({ ...state, panel: selected });
    }


    useEffect(() => {
        setState({ ...state, loading: true })
        healthStatusActions.getSpotHealth().then((response: any) => {
            const spotData = response.filter((o: any) => o);
            const failed = spotData.filter((o: any) => !o.flag);
            const counts = { failed: failed.length, pending: 0, success: spotData.length - failed.length };
            healthStatusActions.addToCount(counts, { tab: prefix, items: failed ,  mapBy : 'name'}).then();
            setState({ ...state, data: spotData, response: spotData, counts, loading: false });
        })
    }, [])

    const view = (passed: boolean) => {
        if (passed)
            setState({ ...state, data: response.filter((o: any) => o.flag) });
        else
            setState({ ...state, data: response.filter((o: any) => !o.flag) });
    }
    return (
        <>
            <AccordionTitle active={panel === prefix} onClick={() => onSelect(prefix)}>
                <Grid>
                    <GridRow>
                        <GridColumn width={13} >
                            <Icon name='dropdown' />
                            <span style={{ fontSize: '15px', marginRight: '30px' }}>Spot Health</span>
                            {state.loading && <Loader active size='tiny' inline='centered' />}
                        </GridColumn>
                        <GridColumn width={3}>
                            <ProgressBar failed={counts.failed} pending={counts.pending} success={counts.success} />
                        </GridColumn>
                    </GridRow>
                </Grid>
            </AccordionTitle>
            <AccordionContent active={panel === prefix}>
                {!loading && <Grid>
                    <GridRow>
                        <GridColumn width={16}>
                            <ButtonGroup >
                                <Button onClick={() => view(true)} icon='folder outline' color='green' content={`Passed ${(counts.success)}`} />
                                <Button onClick={() => view(false)} labelPosition='right' icon='folder outline' color='red' content={`Failed ${(counts.failed)}`} />
                            </ButtonGroup>
                            {state.data && <SpotHealthTable records={state.data} />}
                        </GridColumn>
                    </GridRow>
                </Grid>}

            </AccordionContent>
        </>
    );
}

export default SpotHealth;