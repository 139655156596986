
import React, { useState } from 'react'
import {
    ModalContent,
    ModalActions,
    Button,
    Header,
    Icon,
    Modal,
    List,
    ListContent,
    ListHeader,
    ListItem,
    Grid,
    GridColumn,
} from 'semantic-ui-react'
import * as HealthStatusActions from "../../redux/actions/healthStatus";
import { useActions } from '../../redux/actions';
import { useSelector } from 'react-redux';
import { TABS } from './healthStatusUtils';


interface Props {
    open: boolean,
    close: Function
}

function EmailModal({ open, close }: Props) {

    const [state, setStates] = useState({ tickets: healthItems });
    const healthStatusActions = useActions(HealthStatusActions);
    const states: any = useSelector((state) => state);
    const fails: any[] = states.trackHealthFails.data;
    const user = states.auth.user;
    const { tickets } = state;
    const setTicket = (ticket: HealthItem) => {
        const updatedTickets = tickets.map(o =>
            o.name === ticket.name ? { ...o, flag: !o.flag } : o
        );
        setStates({ ...state, tickets: updatedTickets });
    }



    const sendEmail = async () => {
        const selectedTickets = tickets.map(t => {
                const selected = fails.find(f => f.name === t.name);
                return selected && t.flag  ? { name: t.label, description: selected.description } : null;
            })
            .filter(Boolean);
        await healthStatusActions.emailSupport(selectedTickets , user?.email);
        close(false);
    }

    return (
        <Modal basic open={open} size='small'>
            <Header icon>
                <Icon name='comments outline' />
                Email Support
            </Header>
            <ModalContent>
                <List divided inverted animated selection relaxed>
                    {
                        tickets.map(item => <ListItem key={item.name}>
                            <ListContent>
                                <Grid onClick={() => setTicket(item)}>
                                    <GridColumn floated='left' width={10}>
                                        <ListHeader>{item.label}</ListHeader>
                                        {item.description}
                                    </GridColumn>
                                    <GridColumn floated='right' >
                                        {item.flag ? <Icon color='green' name='checkmark' /> : <Icon color='red' name='close' />}
                                    </GridColumn>
                                </Grid>
                            </ListContent>
                        </ListItem>)
                    }
                </List>
            </ModalContent>
            <ModalActions>
                <Button basic color='red' inverted onClick={() => close(false)}>
                    <Icon name='remove' /> Cancel
                </Button>
                <Button color='green' inverted onClick={() => sendEmail()}>
                    <Icon name='send' /> Send
                </Button>
            </ModalActions>
        </Modal>
    )
}

export default EmailModal


interface HealthItem {
    name: string
    label: string,
    description: string
    flag: boolean
}


const healthItems: HealthItem[] = [
    { name: TABS.SPOT_HEALTH, label: 'Spot Health', description: 'About last visit and last schedule for each practice', flag: false },
    { name: TABS.OPEN_DENTAL_CONNECTIONS, label: 'Open Dental Connections', description: 'Open dental connections for each practice', flag: false },
    { name: TABS.APTOOD, label: 'APTOOD', description: 'APTOOD connections for each practice', flag: false },
    { name: TABS.XRAY_SERVICES, label: 'XRay Services', description: 'Xray services across all practices', flag: false },
    { name: TABS.CRONS, label: 'Crons', description: 'List of all active crons', flag: false },
    { name: TABS.MYSQL, label: 'MySQL', description: 'MySQL and event schema statistics', flag: false },
    { name: TABS.DB_MIGRATIONS, label: 'Database Migrations', description: 'AWS database migrations across all practices', flag: false },
]