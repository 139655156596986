import React, { useState} from 'react';
import {Table, Checkbox, Popup, Dropdown, Icon, Button, Input, TextArea, Modal} from 'semantic-ui-react';
import * as practiceAction from "../redux/actions/practiceAdmin";
import {useActions} from "../redux/actions";
import PracticeAdminODconfig from "./PracticeAdminODconfig";
import {practiceAdminColumnNames, practiceAdminDCRMColumnNames, practiceAdminMoreColumnNames} from "../constants";

interface Data {
    [key: string]: any;
}

interface Props {
    data: Data[];
    reLoadData : Function
}
interface EditData {
    [key: string]: any;
}

interface ModalMoreData {
    name: string;
    practiceAdminColumns : any[],
    fullscreen:boolean
}

const PracticeAdminDynamicTable: React.FC<Props> = (props, context) => {
    const practiceActions = useActions(practiceAction);
    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [order, setOrder] = useState<boolean>(false);
    const [sortKey, setSortKey] = useState<string | undefined>(undefined);
    const [sortDirection, setSortDirection] = useState<'ascending' | 'descending'>('ascending');
    const [editRow, setEditRow] = useState(null);
    const [editData, setEditData] = useState<EditData>({});
    const [OdConfigPracticeData, setOdConfigPracticeData] = useState({});

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState<ModalMoreData>({name:'',practiceAdminColumns:[], fullscreen:false});

    const handleMore = () => {
        setModalContent({name:'More Content', practiceAdminColumns:practiceAdminMoreColumnNames,fullscreen:false});
        setIsModalOpen(true);
    };

    const handleDCRM = () => {
        setModalContent({name:'DCRM Content', practiceAdminColumns:practiceAdminDCRMColumnNames,fullscreen:true});
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent({name:'',practiceAdminColumns:[],fullscreen:false});
    };

    const data = props.data;
    const handleEdit = (rowIndex: any) => {
        setEditRow(rowIndex);
        setEditData(sortedData[rowIndex]);
    };

    const handleShowODconfig = (rowIndex: number) => {
        const practiceId = sortedData[rowIndex] && sortedData[rowIndex].id;
        const configData = sortedData[rowIndex] && sortedData[rowIndex].PracticeCommunicationConfig
        const isReachable = sortedData[rowIndex] && sortedData[rowIndex].isReachable;
        setOdConfigPracticeData({practiceId, configData, isReachable})
    }

    const handleSave = async () => {
        // save the edited data here
        if (editRow !== null) {
            await practiceActions.updatePractice(editData);
            setEditRow(null);
            setEditData({});
            props.reLoadData();
        }
    };


    const savePracticeODconfig = async (data: any) => {
        await practiceActions.saveOpenDentalConfig(data);
        props.reLoadData();
    }

    const closeConfigModal = () => {
        setOdConfigPracticeData({})
    }

    const handleCancel = () => {
        setEditRow(null);
        setEditData({});
    };

   
    const handleChangedCell = (event: any, data: any, columnName: string) => {
        setEditData((prev) => {
            // Check if columnName exists directly in editData or under UdaPracticeInfo
            if (prev.UdaPracticeInfo && prev.UdaPracticeInfo[columnName] !== undefined) {
                // Update under UdaPracticeInfo if found
                return {
                    ...prev,
                    UdaPracticeInfo: {
                        ...prev.UdaPracticeInfo,
                        [columnName]: data.value
                    }
                };
            } else {
                // Otherwise update directly in editData
                return {
                    ...prev,
                    [columnName]: data.value
                };
            }
        });
    };


    const handleCheckBoxCell = (event: any, data: any, columnName: any) => {
        setEditData((prev) => ({...prev, [columnName]: data.checked}));
        if(columnName == "isDeleted" && data.checked){
            setEditData((prev) => ({...prev, ['deletedAt']: new Date()}));
        }

        if(columnName == "isDeleted" && !data.checked){
            setEditData((prev) => ({...prev, ['deletedAt']: null}));
        }
    };

    const handleSort = (columnName: string) => {
        if (sortKey === columnName) {
            setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
        } else {
            setSortKey(columnName);
            setSortDirection('ascending');
        }
    };

    if (!data || data.length === 0) return null;
    const columnNames = Object.keys(data && data[0]);

    const sortedData = [...data].sort((a, b) => {
        let sortValue = 0;
        if (a[sortKey || columnNames[0]] < b[sortKey || columnNames[0]]) {
            sortValue = sortDirection === 'ascending' ? -1 : 1;
        } else if (a[sortKey || columnNames[0]] > b[sortKey || columnNames[0]]) {
            sortValue = sortDirection === 'ascending' ? 1 : -1;
        }
        return sortValue;
    });

    const getTable = (practiceAdminColumn: any, main: boolean) => {
        if (!practiceAdminColumn || practiceAdminColumn.length < 1) return <></>;

        return (
            <div className="FreezeTable">
                {OdConfigPracticeData && Object.keys(OdConfigPracticeData).length > 0 ? (
                    <PracticeAdminODconfig
                        handleSave={savePracticeODconfig}
                        data={OdConfigPracticeData}
                        handleClose={closeConfigModal}
                    />
                ) : (
                    ""
                )}
                <Table className="ui table table-striped table-hover celled unstackable zoom-80">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Action</Table.HeaderCell>
                            {practiceAdminColumn.map((columnName: any, index: number) => (
                                <Table.HeaderCell
                                    key={index}
                                    onClick={() => handleSort(columnName.name)}
                                >
                                    <div>
                                        {columnName.display}
                                        <Icon className={order ? direction : "sort"} />
                                    </div>
                                </Table.HeaderCell>
                            ))}
                            {main && <Table.HeaderCell/>}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {sortedData.map((row, rowIndex) => (
                            <Table.Row key={rowIndex}>
                                <Table.Cell>
                                    {editRow === rowIndex ? (
                                        <>
                                            <Button color="green" onClick={handleSave}>
                                                Save
                                            </Button>
                                            <Button
                                                className={"mt10"}
                                                color="orange"
                                                onClick={() => handleShowODconfig(rowIndex)}
                                            >
                                                OpenDental Config
                                            </Button>
                                            <Button
                                                className={"mt10"}
                                                color="red"
                                                onClick={handleCancel}
                                            >
                                                Cancel
                                            </Button>
                                        </>
                                    ) : (
                                        <Button.Group>
                                            <Button
                                                icon="edit"
                                                label="edit"
                                                onClick={() => handleEdit(rowIndex)}
                                            />
                                        </Button.Group>
                                    )}
                                </Table.Cell>
                                {practiceAdminColumn.map((columnName: any, columnIndex: number) => {
                                    const fieldValue =
                                        row[columnName.name] ?? row.UdaPracticeInfo?.[columnName.name];
                                    const editFieldValue = editData[columnName.name]  ?? editData.UdaPracticeInfo?.[columnName.name];

                                    return (
                                        <Table.Cell key={columnIndex}>
                                            {editRow === rowIndex ? (
                                                columnName.name === "id" ? (
                                                    fieldValue
                                                ) : typeof fieldValue === "boolean" ? (
                                                    <Checkbox
                                                        checked={editData[columnName.name]}
                                                        onChange={(event, data) =>
                                                            handleCheckBoxCell(event, data, columnName.name)
                                                        }
                                                    />
                                                ) : typeof fieldValue === "object" &&
                                                !Array.isArray(fieldValue) ? (
                                                    JSON.stringify(editData[columnName.name])
                                                ) : columnName.name
                                                    .toLowerCase()
                                                    .includes("message") ||
                                                columnName.name
                                                    .toLowerCase()
                                                    .includes("subject") ? (
                                                    <TextArea
                                                        value={editData[columnName.name]}
                                                        onChange={(event, data) =>
                                                            handleChangedCell(event, data, columnName.name)
                                                        }
                                                    />
                                                ) : Array.isArray(fieldValue) ? (
                                                    <Dropdown
                                                        fluid={true}
                                                        multiple={true}
                                                        selection={true}
                                                        options={fieldValue.map((item: any) => ({
                                                            key: item.practiceId,
                                                            text: item.brand,
                                                            value: item.brand,
                                                        }))}
                                                        value={editData[columnName.name].map(
                                                            (item: any) => item.brand
                                                        )}
                                                        onChange={(event, data) =>
                                                            handleChangedCell(event, data, columnName.name)
                                                        }
                                                    />
                                                ) : (
                                                    <Input
                                                        value={editFieldValue}
                                                        onChange={(event, data) =>
                                                            handleChangedCell(event, data, columnName.name)
                                                        }
                                                    />
                                                )
                                            ) : typeof fieldValue === "boolean" ? (
                                                <Checkbox checked={fieldValue} />
                                            ) : typeof fieldValue === "object" &&
                                            !Array.isArray(fieldValue) ? (
                                                JSON.stringify(fieldValue)
                                            ) : columnName.name
                                                .toLowerCase()
                                                .includes("message") ||
                                            columnName.name
                                                .toLowerCase()
                                                .includes("subject") ? (
                                                <Popup
                                                    trigger={<div>{fieldValue.slice(0, 15)}</div>}
                                                    content={fieldValue}
                                                />
                                            ) : Array.isArray(fieldValue) ? (
                                                <Dropdown
                                                    fluid={true}
                                                    multiple={true}
                                                    selection={true}
                                                    options={fieldValue.map((item: any) => ({
                                                        key: item.practiceId,
                                                        text: item.brand,
                                                        value: item.brand,
                                                    }))}
                                                    value={fieldValue.map(
                                                        (item: any) => item.brand
                                                    )}
                                                    onChange={handleChange}
                                                />
                                            ) : (
                                                fieldValue
                                            )}
                                        </Table.Cell>
                                    );
                                })}
                                {main && (
                                    <Table.Cell>
                                        <Button primary={true} onClick={handleMore}>
                                            More
                                        </Button>
                                        <Button className={"mt10"} secondary={true} onClick={handleDCRM}>
                                            DCRM
                                        </Button>
                                    </Table.Cell>
                                )}
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
        );
    };



    const handleChange = (event: any, data: any) => {
        setSelectedValues(data.value);
    };
    const direction = order ? 'sort down' : 'sort up';
    return (
        <>
            {getTable(practiceAdminColumnNames,true)}
            <Modal size={modalContent.fullscreen?"fullscreen":"large"} open={isModalOpen} onClose={closeModal}>
                <Modal.Header>{modalContent.name}</Modal.Header>
                <Modal.Content>
                    {getTable(modalContent.practiceAdminColumns,false)}
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={closeModal}>Close</Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default PracticeAdminDynamicTable;
