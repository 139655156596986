import moment from 'moment'
import React from 'react'
import {
    TableRow,
    TableHeaderCell,
    TableHeader,
    TableCell,
    TableBody,
    Table,
    Label,
} from 'semantic-ui-react'
const _ = require('underscore');

interface props {
    records: any
}
const SpotHealthTable = (props: props) => {
    const { records } = props;
    return (
        <>
            <Table striped compact='very'>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Practice</TableHeaderCell>
                        <TableHeaderCell>Last schedule</TableHeaderCell>
                        <TableHeaderCell>Last visit</TableHeaderCell>
                        <TableHeaderCell></TableHeaderCell>
                    </TableRow>
                </TableHeader>

                <TableBody>
                    {
                      records && _.sortBy(records , (item: any) => [item.flag, item.name]).map((logs: any, index: number) =>
                            <TableRow key={index}>
                                <TableCell>{logs.name}</TableCell>
                                <TableCell>{ logs.lastScheduleDate ? moment(logs.lastScheduleDate).format('ll') : '-'}</TableCell>
                                <TableCell>{moment(logs.lastVisit).format('ll')}</TableCell>
                                <TableCell width={3}>{logs.flag ?
                                    <Label style={{width:'100%'}} size='massive' color='green' horizontal></Label> :
                                    <Label style={{width:'100%'}} size='massive' color='red' horizontal></Label>}
                                </TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </>

    )
}

export default SpotHealthTable