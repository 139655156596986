/**
 * simulate server latency (default is 1000 ms)
 * @param ms
 */
export const sleep = (ms: number = 1000) => new Promise(resolve => setTimeout(resolve, ms));

/**
 * stringify a JSON object
 * @param o
 */
export const stringify = (o: Object) => JSON.stringify(o, null, 2);

export const sortBy = (dataArray: any[], key?: string) => {
  return dataArray.sort((a, b) => {
    return (key && key.length > 0) ? (a[key] > b[key]) ? 1 : -1 : (a > b) ? 1 : -1;
  });
  
};

export const sortByDesc = <T>(dataArray: T[], key?: keyof T) => {
  return dataArray.sort((a, b) => {
    return (key) ? (b[key] > a[key]) ? 1 : -1 : (b > a) ? 1 : -1;
  });
};

export const removeUndefinedObjects = <T extends object>(arr: T[]): T[] => {
  return arr.filter((item): item is T => item !== undefined);
};

export const findPractice = (obj: Object, value: string) => {
  let result;
  Object.values(obj).forEach(o => {
    if (o.practiceDBName === value) {
      result = o;
      return true;
    }
  });
  return result;
}

export const isPracticeAirPayEnabled = (obj: Object, practices: any) => {
  return Object.values(obj).some(o => {
    return practices.includes(o.practice) && o.airPay;
  });
};

export  const refinePracticeArray = (response: any) => {
  let practiceNames: Array<string> = [];
  let practiceInfo: any = {};
  let practiceOptions = [{key: 'none', value: 'all', text: 'All Practices'}];
  if (response) {
    response.forEach((item: any) => {
      practiceOptions.push({text: item.practice, value: item.practice, key: item.id.toString()});
      practiceNames.push(item.practice);
      practiceInfo[item.practice.toLowerCase()] = item;
    })
  }
  return {practiceNames: practiceNames, practiceOptions: practiceOptions, practiceInfo: practiceInfo}
};

export const isBlankNullOrUndefined=(value:any)=>{
  if(value === '' || value ===undefined || value === null) {
    return true
  } else {
    return false
  }
}

export const removeNullProperties = (obj:any) => {
  Object.keys(obj).forEach((key:string) => {
    let value = obj[key];
    let hasProperties = value && Object.keys(value).length > 0;
    if ((value === null) || (value === 'null') || (value === '')) {
      delete obj[key];
    }
    if ((key === null) || (key === 'null') || (key === '')) {
      delete obj[key];
    }
    else if ((typeof value !== "string") && hasProperties) {
      removeNullProperties(value);
    }
  });
  return obj;
}

export const getMonthsBetweenDates = (startDate:string,endDate:string) =>{
  var start:any      = startDate.split('-');
  var end:any        = endDate.split('-');
  var startYear:any  = parseInt(start[0]);
  var endYear:any    = parseInt(end[0]);
  var dates:any      = [];

  for(var i:any = startYear; i <= endYear; i++) {
    var endMonth:any = i != endYear ? 11 : parseInt(end[1]) - 1;
    var startMon:any = i === startYear ? parseInt(start[1])-1 : 0;
    for(var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j+1) {
      var month = j+1;
      var displayMonth = month < 10 ? '0'+month : month;
      dates.push([i, displayMonth, '01'].join('-'));
    }
  }
  return dates;
};

export const makeOSPCRPopupCSV=(finalData:any)=> {
  let dataArr = []
  if (finalData && finalData.length > 0) {
    dataArr = finalData.map((item: any, key: number) => `${item.patientNum},${item.appStatus},${item.aptDate},${item.OpName}`);
  }
  dataArr.unshift("Patient Num, Appointment Status, Op Name");
  return dataArr;
}
export const addPropToArray = (arr:any,practice:string) => {
  for (let prop of arr){
    prop.Location = practice;
  }
  return arr;

}

/**
 * object is valid
 * @param obj
 */
export const validObj = (obj: object | undefined) => !!(obj && Object.keys(obj).length);

/**
 * Valid Array
 */
export const validArr = (arr: any): boolean => arr && Array.isArray(arr) && !!(arr.length);

/**
 * Normal string to CamelCase
 */
export const strToCamelCase = (str: string) =>  str.toLowerCase().trim().split(" ").reduce((s,c)=> s + (c.charAt(0).toUpperCase()+ c.slice(1) ));

/**
 * Check array is empty or not
 */
export const isEmpty = (arr: any) => Array.isArray(arr) && !arr.length;

export const deleteCookie = async() => {
  const cookie1 = 'cdp-cookie';
  document.cookie = cookie1 + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path='/';"
};

export const getCookie = (cname: any) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const setCookie = (cname: string,cvalue: string,exdays: number) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const setPractices = (res: any, idAsValue = false) => {
  let practiceList: any = [{key: 'all', value: '', text: 'Select Practice'}];

  if (Object.keys(res).length) {
    res.forEach((item: any) => {
      practiceList.push({
        key: item.id,
        value: idAsValue ? item.practiceId : item.practiceName,
        text: item.practiceName?? item.practice
      })
    })
  }
  return practiceList
}

export const setBrands = (res: any) => {
  let brandList: any = [{key: 'all', value: '', text: 'Select Brand'}];
  const distinctByBrand = res.reduce((acc:any, current:any) => {
    if (!acc.some((item:any) => item.brand === current.brand)) {
      if (current.brand !== null && current.brand !== "" && isNaN(Number(current.brand))){
        acc.push(current);
      }
    }
    return acc;
  }, []);
  if (Object.keys(distinctByBrand).length) {
    distinctByBrand.forEach((item: any) => {
      brandList.push({
        key: item.brand,
        value: item.brand,
        text: item.brand
      })
    })
  }
  return brandList
}

export const setRegionsFromPractice = (res: any) => {
  let regionList = [{key: 'all', value: 'all', text: 'Select Region'}]
  const distinctByRegion = res.reduce((acc:any, current:any) => {
    if (!acc.some((item:any) => item.regionId === current.regionId)) {
      if (current.regionId !== null && current.brand !== 0) {
        acc.push(current);
      }
    }
    return acc;
  }, []);
  if (Object.keys(distinctByRegion).length) {
    distinctByRegion.forEach((item: any) => {
      if(item.Region)
      regionList.push({
        key: item.regionId,
        value: item.regionId,
        text: item?.Region?.name,
      })
    })
  }

  return regionList
}



export const setRegions = (res: any) => {
  let regionList = [{key: 'all', value: 'all', text: 'Select Brand'}]

  if (Object.keys(res).length) {
    res.forEach((item: any) => {
      regionList.push({
        key: item.id,
        value: item.id,
        text: item.name
      })
    })
  }

  return regionList
}

export function convertToCSV(data: any) {
  const header = Object.keys(data[0]).map(escapeCSVValue).join(",");
  const rows = data.map((item: any) =>
      Object.values(item).map(escapeCSVValue).join(",")
  );
  return [header, ...rows].join("\n");
}

function escapeCSVValue(value: any) {
  // Check if the value contains special characters like quotes or commas
  if (/[",\n]/.test(value)) {
    // If it does, wrap the value in double quotes and escape any existing double quotes
    return `"${value.replace(/"/g, '""')}"`;
  }
  return value;
}

export function calculateAge(dob: string) {
  const currentDate = new Date();
  const birthdate = new Date(dob);

  let age = currentDate.getFullYear() - birthdate.getFullYear();

  // Adjust age if birthday hasn't occurred yet this year
  if (currentDate.getMonth() < birthdate.getMonth() || (currentDate.getMonth() === birthdate.getMonth() && currentDate.getDate() < birthdate.getDate())) {
    age--;
  }

  return age;
}

