import React, { useEffect, useState } from "react";
import { useActions } from "../../redux/actions";
import * as Actions from "../../redux/actions/users";
import {Button, Checkbox, Form, Modal} from "semantic-ui-react";
import * as permissionsActions from '../../redux/actions/permissions';
import { permissions, permissionsOptions, queueOptions, typeOptions,schedulePermissions } from "./constants";
import Loading from "../../Loading";
import * as UserActions from "../../redux/actions/userSettings";
import * as spotActions from "../../redux/actions/spot";
import { SetupFields } from "../../enums/userSetupField";
import { SetupAppName } from "../../enums/userSetupAppName";

interface props {
    onClose: (view: boolean) => void,
    onSave: () => void,
    view: boolean,
    practices: { options: any[], all: any[] },
}

const AddUser = (props: props) => {
    const initialState = {
        defaultPractices: "",
        loader: true,
        user: {} as any,
        udrPermissionOptions: [] as any[],
        notifyGroups: [] as any[],
        practiceOptions: [] as any[],
    }

    const [state, setState] = useState(initialState);
    const [showOdUsername, setShowOdUsernam] = useState(false);
    const permissionsAction = useActions(permissionsActions);
    const spotAction = useActions(spotActions);
    const usersActions = useActions(Actions);
    const userSettingsActions = useActions(UserActions);

    useEffect(() => {
        const setData = async () => {
            const result = await Promise.all([
                await permissionsAction.getUdrPermissions(), 
                await permissionsAction.getNotificationGroups(),
                await spotAction.fetchPractices()
            ]);

            const udrPermissionOptions = result[0].map((m: any) => { return { text: m.role, value: m.id, key: m.id } });
            const notifyGroups = result[1].map((m: any) => { return { text: m.group, value: m.id.toString(), key: m.id } });
            const practiceOptions = result[2].map((p: any) => { return { text: p.practice, value: p.practiceDBName, key: p.id } });

            let practiceIds = ['All'];
            const practices = props.practices.options
                .filter((practice: any) => practiceIds.includes(practice.value))
                .map((a: any) => a.key).join(';');

            setState({
                ...state,
                loader:false,
                practiceOptions,
                udrPermissionOptions,
                notifyGroups
            });
        };
    
        setData();
    }, []);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        field: string
      ) => {
        const user = state.user;
        user[field] = e.target.value;
        setState({...state, user})
    };

    const onSelectDefaultPracticeChange = (value: any) => {
        setState({...state, defaultPractices: value});
    };


    const onSelectChange = (value: any, name: string) => {
        const { user } = state;
        const permission = { ...user?.permission, [name]: value }
        if(permission.permissions&&permission.permissions.includes('callcenter')) {
            setShowOdUsernam(true)
        } else {
            setShowOdUsernam(false)
        }
        setState({ ...state, user: { ...user, ...permission, permission } });
    }


    const onSelectPracticeChange = (data: any) => {
        let { user } = state;
        let permission:any ={};
        if(data.value.length>20 || data.value.includes('All')) {
            const [, ...practices] = data.options.map((a:any)=>a.value);
            user.defaultLocationId = practices.join(';')
            permission.practice = data.options.map((a:any)=>a.value)[0];
            user.practices = data.options.map((a:any)=>a.value)[0];
        }
        else {
            user.defaultLocationId = data.value.join(';')
            const selectedPracticeKeys = data.options
                .filter((practice: any) => data.value.includes(practice.value))
                .map((a: any) => a.key);
            permission.practice = selectedPracticeKeys.join(';');
            user.practices = selectedPracticeKeys.join(';');
        }
        setState({ ...state, user: { ...user, ...permission } });
    }

    const onSelectNotificationChange = (value: any) => {
        let { user } = state;
        user.notificationPermission = value.join(';');
        setState({ ...state, user: { ...user } });
    }

    const saveDetails = async () => {
        setState({ ...state, loader: true });
        let user = state.user;
        const selectedPermissions: string[] = user?.permissions;
        const perms: any = permissions?.reduce((o, key) => ({ ...o, [key.value]: selectedPermissions?.includes(key.value) ? 1 : 0 }), {});
        ['createdAt', 'updatedAt', 'udrUsersPermissions', 'udrPermissions','types',].forEach(key => delete user[key]);
        const details = { ...user, permissions: { ...perms } };
        const setup = {
            appId: SetupAppName.CDP_MY,
            userId: user?.id,
            field: SetupFields.Practice,
            value: state.defaultPractices.toString(),
        };

        await Promise.all([
            await usersActions.saveUserInfo({user: details}),
            await userSettingsActions.createOrUpdateSetup(setup)
        ]);

        props.onSave();
    }

    const handleCheckboxChange = (e: any, {name, checked}: any) => {
        const { user } = state;
        setState({...state, user: { ...user, [name]: checked } });
    };

    const setForm = () => {
        const {user, loader} = state;
        if(loader){
            return <><Loading /></>
        }else{
            return <>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Input fluid label='First name' placeholder='First name'
                                onChange={(e) => handleChange(e, 'firstname')}/>
                    <Form.Input fluid label='Last name' placeholder='Last name'
                                onChange={(e) => handleChange(e, 'lastname')}/>
                    <Form.Input fluid label='Username' placeholder='Username'
                                onChange={(e) => handleChange(e, 'username')}/>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Input type='password' autocomplete="off" name='password' fluid label='Password' placeholder='Password'
                                onChange={(e) => handleChange(e, 'password')}/>
                    <Form.Input fluid label='Email' placeholder='email'
                                onChange={(e) => handleChange(e, 'email')}/>                    
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Input fluid label='Educator Manager' placeholder='Educator manager'
                                onChange={(e) => handleChange(e, 'manager')}/>
                    <Form.Input fluid label='Regional manager' placeholder='Regional manager'
                                onChange={(e) => handleChange(e, 'regionalmanager')}/>
                     <Form.Select fluid label='Schedule role'  options={schedulePermissions}
                                 onChange={(_e, data) => onSelectChange(data.value, 'srole')} placeholder='Schedule Role'
                                 />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Input fluid label='Copy manager' placeholder='Copy manager'
                                onChange={(e) => handleChange(e, 'specialmanager')}/>
                    <Form.Select fluid label='Queue' options={queueOptions} placeholder='Queue'
                                 onChange={(_e, data) => onSelectChange(data.value, 'queue')}/>
                    <Form.Select fluid label='Type' options={typeOptions} placeholder='Type'
                                 onChange={(_e, data) => onSelectChange(data.value, 'types')}/>
                </Form.Group>

                <Form.Group widths='equal'>
                    <Form.Select label='Practices' multiple options={props.practices.options} placeholder='practices'
                                 onChange={(_e, data) => onSelectPracticeChange(data)}/>
                    <Form.Select fluid label='Notification' multiple placeholder='Notification'
                                 options={state.notifyGroups}
                                 onChange={(_e, data) => onSelectNotificationChange(data.value)}/>
                    <Form.Select fluid label='Uda role' multiple options={state.udrPermissionOptions}
                                 onChange={(_e, data) => onSelectChange(data.value, 'udaRole')} placeholder='Uda roles'
                                 />
                </Form.Group>

                <Form.Group widths='equal'>
                    <Form.Select fluid label='Permissions' multiple options={permissionsOptions}
                                 placeholder='Permissions'
                                 onChange={(_e, data) => onSelectChange(data.value, 'permissions')}/>
                    <Form.Select 
                        fluid label='Default Practice' 
                        multiple 
                        search
                        options={state.practiceOptions}
                        placeholder='Default Practice'
                        onChange={(_e, data) => onSelectDefaultPracticeChange(data.value)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Field>
                        <label>SPOT Export</label>
                        <Checkbox
                            name="spotExport"
                            toggle={true}
                            onChange={handleCheckboxChange}
                        />
                    </Form.Field>
                </Form.Group>

            </Form>
        </>
        }
        
    }

    return (
        <Modal onClose={() => props.onClose(false)} onOpen={() => props.onClose(true)} open={props.view}>
            <Modal.Header>Add User</Modal.Header>
            <Modal.Content  style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                {setForm()}
            </Modal.Content>
            <Modal.Actions>
                <Button primary loading={state.loader} onClick={() => saveDetails()}>Save</Button>
                <Button content='Close' onClick={() => props.onClose(false)}/>
            </Modal.Actions>
        </Modal>
    );
};

export default AddUser
