import React, { useEffect, useState } from 'react';
import { AccordionTitle, AccordionContent, Icon, Grid, GridRow, GridColumn, Loader } from 'semantic-ui-react'
import * as HealthStatusActions from "../../../redux/actions/healthStatus";
import { useActions } from '../../../redux/actions';
import ProgressBar from '../ProgressBar';
import PatientValidTable from './PatientValidTable';
import { TABS } from '../healthStatusUtils';

function PatientValidationHealth() {
    const initialState = { loading: false, data: [], panel: '', counts: { success: 0, failed: 0, pending: 0 } };
    const healthStatusActions = useActions(HealthStatusActions);
    const [state, setState] = useState(initialState);
    const { loading, panel, data , counts } = state;
    const prefix = TABS.PATIENT_VALIDATION;

    useEffect(() => {
        setState({ ...state, loading: true })
        healthStatusActions.getPatientValHealth().then((response: any) => {
            const counts = getCount(response);
            healthStatusActions.addToCount(counts).then();
            setState({ ...state, counts, data: response, loading: false });
        })
    }, [])

    const onSelect = (selected: string) => {
        if (panel === selected)
            setState({ ...state, panel: '' });
        else
            setState({ ...state, panel: selected });
    }

    const getCount = (data:any[]) => {
        const result = data.reduce((acc, item) => {
            acc.fail += item.fail;
            acc.total += item.total;
            acc.blank += item.blank;
            return acc;
        }, { fail: 0, total: 0 , blank: 0 });
        return { success: result.total - result.fail, failed: result.fail, pending: result.blank }
    }

    return (
        <>
            <AccordionTitle active={state.panel === prefix} onClick={() => onSelect(prefix)}>
                <Grid>
                    <GridRow>
                        <GridColumn width={13} >
                            <Icon name='dropdown' />
                            <span style={{ fontSize: '15px', marginRight: '30px' }}>Patient Validation</span>
                            {loading && <Loader active size='tiny' inline='centered' />}
                        </GridColumn>
                        <GridColumn width={3}>
                            <ProgressBar failed={counts.failed} pending={counts.pending} success={counts.success} />
                        </GridColumn>
                    </GridRow>
                </Grid>
            </AccordionTitle>
            <AccordionContent active={panel === prefix}>
                {!loading && <Grid>
                    <GridRow>
                        <GridColumn width={16}>
                            <PatientValidTable data={data} />
                        </GridColumn>
                    </GridRow>
                </Grid>}
            </AccordionContent>
        </>
    );
}

export default PatientValidationHealth;