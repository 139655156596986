import { Link } from 'react-router-dom';
import {
  AgeTypes,
  ByCodeTypes,
  ReportInitialState,
  OutlookTypes,
  InsuranceTypes
} from "./model/report";
import { DropdownItemProps, SemanticICONS } from 'semantic-ui-react';
export const API_URL: string = process.env.NODE_ENV === "development"
  ? "http://localhost:5006"
  : "https://myapi.cdp.dental";

export const BULK_PROCESSES_API_URL: string = process.env.NODE_ENV === "development"
? "http://localhost:5006"
: "https://myapi-crons.cdp.dental";

export interface SubMenu {
  pageId?: number;
  name: string;
  icon: SemanticICONS;
  redirectUrl?: string,
  title: string;
  role: string[];
  hide?: boolean;
  customIcon?: string;
  grandSubMenu?: GrandSubMenu[];
}
export interface GrandSubMenu {
  pageId?: number;
  name: string;
  icon: SemanticICONS;
  title: string;
  role: string[];
  hide?: boolean;
  customIcon?: string;
}

export interface MainMenu {
  class: string;
  customIcon?: string;
  icon: SemanticICONS;
  name: string;
  pageId?: number;
  partner: string;
  subMenus?: SubMenu[];
  title: string;
  role: any;
  hide?: boolean;
  parentLink?: string;
}

export const openDentalDataApiCategories = {
  "byPracticeReport":0,
  "bySplashBoardReport":1,
  "byAgeReport":2,
  "byAgeMixReport":3,
  "byBudgetByWorkingReport":4,
  "byProviderCPVReport":5,
  "byPCRInsuranceReport":6,
  "byPCRInsuranceMixReport":7,
  "byOutlookReport":8,
  "byPcrCpvReport":9,
  "byPcrRegionRollUp":10,
  "byPcrBrandRollUp":11
};

export const brands = [
  {text: 'All', value: 'All', key: 'All'},
  {text: 'SMD', value: 'SMD', key: 'SMD'},
  {text: 'FSD', value: 'FSD', key: 'FSD'},
  {text:'OTD',value:'OTD',key:'OTD'},
  {text:'SDD',value:'SDD',key:'SDD'},
  {text:'3CD',value:'3CD',key:'3CD'},
  {text:'SYN',value:'SYN',key:'SYN'}
];

export const ODallowedApis = [
  { text: 'commlogs', value :'commlogs', key:'commlogs'},
  { text: 'userods', value :'userods', key:'userods'},
  { text: 'usergroups', value :'usergroups', key:'usergroups'},
  { text: 'appointments', value :'appointments', key:'appointments'},
  { text: 'documents', value :'documents', key:'documents'},
  { text: 'claims', value :'claims', key:'claims'},
  { text: 'patients', value :'patients', key:'patients'},
]

export const providerProfilesOptions = [
  { text:'All',value: 'All', key: "All"},
  { text:'Active',value: 'Active', key: "Active"},
  { text:'InActive',value: 'InActive', key: "InActive"}
];
export const LIVE = "Live";
export const BETA = "Beta";
export const ALL = "All";

export const awsWebsitesOptions = [
  { text:'All',value: ALL, key: "All"},
  { text:'Beta',value: BETA, key: "Beta"},
  { text:'Live',value: LIVE, key: "Live"}
];

export const doctorPracticeTypes: { [key: string]: string } = {'1': '1 Dr', '2': '2 Dr', '3': 'Denovo'};
export const createDrListOptions = () => {
  let drListOptionsArray: any = [{key: '0', value: 0, text: 'Select DrType'}];
  if (Object.keys(doctorPracticeTypes).length) {
    Object.keys(doctorPracticeTypes).forEach((key: string) => {
      drListOptionsArray.push({text: doctorPracticeTypes[key], value: key, key: key})
    });
  }
  return drListOptionsArray;
};
export const drListOptions = createDrListOptions();

export enum myPartners {
  SCHEDULE = 'Schedule',
  SITES = 'Sites',
  DENTIST = 'Dentist',
  LEADS = 'Leads',
  PCR = 'PCR',
  ADMIN = 'Admin',
  SPOT = 'SPOT',
  DCRM = 'DCRM',
  SPORE = 'SPORE',
  SCHEDULER='SCHEDULER',
  CLAIMS_TEAM='CLAIMS TEAM',
  CALL_CENTER='CALL CENTER',
  CSR = 'CSR',
  DIRECTORY_ORG_CHART='DIRECTORY_ORGCHART',
  ADJUSTMENT = 'Adjustment',
  FIVE9 ='Five9',
  MDM ='MDM',
  PT_VALIDATION ='PT_VALIDATION',
  MDHMS ='MDHMS',
  ALERTS = 'ALERTS',
  PMD = 'PMD',
  RECRUIT = 'RECRUIT',
  XSD = 'XSD',
  PF = 'Patient Finance',
  CRED = 'Credentialing',
  MATRIX = 'User Matrix',
  REFERRALS='REFERRALS',
  MEMBERS='MEMBERS',
  EDUCATOR_NOTIFICATIONS = 'EDUCATOR_NOTIFICATIONS',
  FOUR11 = 'FOUR11',
  PCO='SPECIALTY_PCO',
  TAB32= 'TAB32',
  MONDAY_APP= 'MONDAY_APP',
  TAB32_QUERIES= 'TAB32_QUERIES',
  WEBSITES = 'WEBSITES',
  APTOOD = 'APTOOD',
  DENTAL_CRM = 'Dental CRM',
  EXEC_CARE_METRICS = 'Excutive Care Metrics',
  HEALTH_STATUS = 'HEALTH_STATUS',
  OPENDENTAL_INSURANCE = 'OPENDENTAL_INSURANCE',
  ODUSER = 'ODUSER',
  AIRPAY_PATIENTS = 'AIRPAY_PATIENTS',
}

export const csrHeader = [
  {key: 'row', title: 'Row labels'},
  {key: 'apt count', title: 'Apt Count'},
  {key: 'show', title: 'Show'},
  {key: 'dr count', title: 'Dr Count'},
  {key: 'dentist', title: 'PTs/Dentist'},
  {key: 'total scheduled care', title: 'Total Care Scheduled'},
  {key: 'expected scheduled care', title: 'Scheduled Care Expected'},
  {key: 'broken', title: 'Broken'},
  {key: 'confirmed apt', title: 'All Confirmed Apt/Dentist'},
  {key: 'apts confirmed', title: 'Tx APts Confirmed'},
  {key: 'solution', title: 'Confirmed Digitally'},
  {key: 'apt sparkline', title: 'APT 7am - 6pm'},
  {key: 'confirmed sparkline', title: 'Confirmed 7am - 6pm'},
];

export const ospcrHeader = [
  {key: 'FSDPNO', title: 'FSDPNO'},
  {key: 'SMDCAR', title: 'SMDCAR'},
  {key: 'SMDLVL', title: 'SMDLVL'},
  {key: 'GrandTotal', title: 'Grand Total'},  
];
export const byAgeObjkeys:{[key:string]: AgeTypes} = { row4: '<3', row5: '3-5', row6: '6-8', row7: '9-11', row8: '12-14', row9: '15-17', row10: '18&over'};
export const byAgeMixObjkeys:{[key:string]: AgeTypes} = { row1: '<3', row2: '3-5', row3: '6-8', row4: '9-11', row5: '12-14', row6: '15-17', row7: '18&over', row8: 'total'};
export const byInsuranceObjectkeys:{[key:string]: InsuranceTypes} ={row1:'medicaid',row2:'ppo',row3:'cash',row4:'medicare',row5:'total'}
export const outLookObjkeys:{[key:string]: OutlookTypes} = { row1: 'Patient Budget', row2: 'Completed Patients',
  row3: 'Show Rate', row4: 'Expected Show',
  row5: 'Patients Needed', row6: 'Appointments Needed', row7: '% to Goal',row8: 'Days Left',
  row9: 'More Apts Needed/Day',};

export const correctAptStatus = (status:string) =>{
  let newStatus = status;
  status = status.toUpperCase();
  switch (status){
    case 'SCHEDULED':
    case 'CONFIRMED':
    case 'CONFIRMED_AUTO':
    case 'IN_CHAIR':
      return 'Scheduled';
    case 'COMPLETE':
    case 'CHECKED_OUT':
    case 'BILLED':
      return 'Complete';
    case 'BROKEN':
    case 'CANCELLED':
    case 'NO_SHOW':
    case 'RESCHEDULED':
      return 'Broken';
    default:
      return newStatus
  }
};

export const checkUndefinedAptStatus = (status:string) =>{
  status = status.toUpperCase();
  switch (status){
    case 'RESCHEDULED':
    case 'SCHEDULED':
    case 'CONFIRMED':
    case 'CONFIRMED_AUTO':
    case 'IN_CHAIR':
    case 'COMPLETE':
    case 'CHECKED_OUT':
    case 'BILLED':
    case 'BROKEN':
    case 'CANCELLED':
    case 'NO_SHOW':
      return false;
    default:
      return true
  }
};


export const byCodeTypes:ByCodeTypes[] = ['FV', 'RC', 'TX'];

export const reportInitialState:ReportInitialState = {
  filters: { practice: [], drType: [], region: [], date: null, month: "",brands:[]},
  data: null,
  newReport:null,
  selectedPractices: [],
  refreshData: false,
  todaysGoalAllData: {},
  appIsDown: false
};

export const spotLightClasses = {
  green: 'spotLightGreen',
  red: 'spotLightRed',
  yellow: 'spotLightYellow'
};

export enum DateFormat {
  LastDay = "MM/DD ddd"
}

export const oralSurgeryBudgetHeader = [{
  title: 'Practice',
  isEditable: false
}, {
  title: 'Month Year',
  isEditable: false
}, {
  title: 'Oral Surgery Days',
  isEditable: false
}, {
  title: 'PT Visits',
  isEditable: true
}, {
  title: 'CPV',
  isEditable: true
}, {
  title: 'Budget Care',
  isEditable: false
}];

export const monthsArray:any = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export enum Months { 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'}

export const csvHeader = ['Practice', 'Date', 'Apt Count', 'Show', 'Dr Count',
  'PTs/Dentist', 'Total Care Scheduled','Scheduled Care Expected' , 'All Confirmed Apt/Dentist',
  'Tx APts Confirmed', 'Confirmed Digitally'];

export const csvPatientValidationPracticeLogsReport = ['Last Login Date', 'Practice', 'Office Manager', 'Region Manager'];

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const CSRGraphOptions = {
  maintainAspectRatio: false,
  legend: {display: false},
  tooltips: {enabled: false},
  scales: {
    xAxes: [{
      ticks: {display: false},
      stacked: false,
      gridLines: {
        display: false,
        drawBorder: false
      },
    }],
    yAxes: [{
      ticks: {display: false},
      stacked: false,
      gridLines: {
        display: false,
        drawBorder: false
      },
    }]
  }
};

export const priorities:DropdownItemProps[] =[
  {key:'P1', text:'P1', value:'P1'},
  {key:'P2', text:'P2', value:'P2'},
  {key:'P3', text:'P3', value:'P3'},
  {key:'P4', text:'P4', value:'P4'},
  {key:'P5', text:'P5', value:'P5'},
];

export const leadStatus:DropdownItemProps[] =[
  {key:'Purge Won', text:'Purge Won', value:'Purge Won'},
  {key:'Purge Lost', text:'Purge Lost', value:'Purge Lost'}
];

export const pcrCodeTypes:ByCodeTypes[] = ['FV', 'RC', 'TX'];



export const budgetTypes: any = {
  '1': 'FV Visits',
  '2': 'RC Visits',
  '3': 'TX Visits',
  '4': 'FV CPV',
  '5': 'RC CPV',
  '6': 'TX CPV',
  '7': 'BRAND'
}


export const budgetFilters:any = {
  type: [
    { key: 0, text: 'Select Type', value: 0 },
    { key: 1, text: 'FV Visits', value: 1 },
    { key: 2, text: 'RC Visits', value: 2 },
    { key: 3, text: 'TX Visits', value: 3 },
    { key: 4, text: 'FV CPV', value: 4 },
    { key: 5, text: 'RC CPV', value: 5 },
    { key: 6, text: 'TX CPV', value: 6 },
    { key: 7, text: 'BRAND', value: 7 },
  ],
  years: [
    { key: 0, value: '', text: 'Select Start Year' },
    { key: 1, value: '2015', text: '2015' },
    { key: 2, value: '2016', text: '2016' },
    { key: 3, value: '2017', text: '2017' },
    { key: 4, value: '2018', text: '2018' },
    { key: 5, value: '2019', text: '2019' },
    { key: 6, value: '2020', text: '2020' },
    { key: 7, value: '2021', text: '2021' },
    { key: 8, value: '2022', text: '2022' },
    { key: 9, value: '2023', text: '2023' },
    { key: 10, value: '2024', text: '2024' },
    { key: 11, value: '2025', text: '2025' }
  ],
  months: [
    { key: 0, value: '', text: 'Select Start Month' },
    { key: 1, value: '01', text: 'Jan' },
    { key: 2, value: '02', text: 'Feb' },
    { key: 3, value: '03', text: 'Mar' },
    { key: 4, value: '04', text: 'Apr' },
    { key: 5, value: '05', text: 'May' },
    { key: 6, value: '06', text: 'Jun' },
    { key: 7, value: '07', text: 'Jul' },
    { key: 8, value: '08', text: 'Aug' },
    { key: 9, value: '09', text: 'Sept' },
    { key: 10, value: '10', text: 'Oct' },
    { key: 11, value: '11', text: 'Nov' },
    { key: 12, value: '12', text: 'Dec' }
  ]
};

export enum reportPageNames {
  budgetWorkDay = 'budget-by-work-day',
  age = 'age',
  ageMix = 'age-mix',
  practice = 'practice',
  splashBoard = 'splashboard',
  cpv = 'cpv',
  insurance = 'insurance',
  insuranceMix = 'insurance-mix',
  outlook = 'outlook',
  byProviderCpv = 'provider-cpv',
  byRegionRollUp = 'region-roll-up',
  byBrandRollUp = 'brand-roll-up',
}

export const defaultProviderDetailsObj = {
  daysWorked: 0,
  cpv: 0,
  Fv: 0,
  Rc: 0,
  Tx: 0,
  medicaid: 0,
  ppo: 0,
  cash: 0,
  cpv3: 0,
  cpv4to10: 0,
  cpv11to21: 0,
  cpv22above: 0,
  provider: ''
};

export enum ByAgeObjByProviderKeys {
  '<=3' = '<=3',
  '4-10' = '4-10',
  '11-21' = '11-21',
  '22&over' = '22&over'
}

export const MDMHeader = [{
  header: 'Action',
  sortBy: null
}, {
  header: 'Fields',
  sortBy: 'field'
},{
  header: 'Hex Code',
  sortBy: 'hexCode'
}, {
  header: 'Short Description',
  sortBy: 'short_desc'
}, {
  header: 'Formula',
  sortBy: 'formula'
}, {
  header: 'Code',
  sortBy: 'code'
}, {
  header: 'Data Source',
  sortBy: 'dataSource'
}, {
  header: 'Report',
  sortBy: 'report'
}, {
  header: 'Page',
  sortBy: 'page'
}];

export const statusOptionsValidation = [{
  key: 0,
  text: 'Select Status',
  value: 0
  },{
  key: 1,
  icon: {name: 'check circle', color: 'grey'},
  text: 'grey',
  value: 1
},{
    key: 2,
    icon: {name: 'check circle', color: 'green'},
    text: 'green',
    value: 2
  },
  {
    key: 3,
    icon: {name: 'check circle', color: 'red'},
    text: 'red',
    value: 3
  },
];
export const statusOptions = [
  {
    key: 'all',
    text: 'Select Status',
    value: 'all'
  },
  {
    key: 'active and assigned',
    icon: {name: 'check circle', color: 'green'},
    text: 'PT Assigned To Practice Active Insurance',
    value: 'PT Assigned To Practice Active Insurance'
  },
  {
    key: 'active',
    icon: {name: 'check circle', color: 'blue'},
    text: 'PT Scraped But Exception Thrown',
    value: 'PT Scraped But Exception Thrown'
  },
  {
    key: 'unactive',
    icon: {name: 'delete', color: 'red'},
    text: 'PT Does NOT Have Active Insurance',
    value: 'PT Does NOT Have Active Insurance'
  },
  {
    key: 'active',
    icon: {name: 'font', color: 'red'},
    text: 'PT Has Active Insurance But Not Assigned To Practice',
    value: 'PT Has Active Insurance But Not Assigned To Practice'
  },
  {
    key: 'cant find members',
    icon: {name: 'question circle', color: 'yellow'},
    text: 'Can\'t Find Member. Check Subscriber ID or Birthdate',
    value: 'Can\'t Find Member. Check Subscriber ID or Birthdate'
  },
];
export const filePushedOptions = [
  {
    key: 'all',
    text: 'Select File',
    value: 'all'
  },
  {
    key: 'pushed',
    icon: {name: 'file image', color: 'green'},
    text: 'File pushed',
    value: 1
  },
  {
    key: 'notpushed',
    icon: {name: 'file image', color: 'red'},
    text: 'File NOT pushed',
    value: 0
  },
  {
    key: 'notpushed',
    icon: {name: 'file image', color: 'blue'},
    text: 'File dates not matching',
    value: 6
  },
  {
    key: 'blank',
    icon: {name: 'question circle', color: 'yellow'},
    text: 'Blank',
    value: 'Blank'
  },
];
export const aptStatusOptions = [{
  key: 'all',
  text: 'Select APT Status',
  value: 'all'
},{
  key: 'scheduled',
  text: 'Scheduled',
  value: 'scheduled'
},
  {
    key: 'broken',
    text: 'Broken',
    value: 'broken'
  },
  {
    key: 'complete',
    text: 'Complete',
    value: 'complete'
  },
  {
    key: 'undefined',
    text: 'Undefined',
    value: 'undefined'
  }
];

export const allowDeleteUserId = [ 163, 1710, 791, 1189, 1180 ];

export const pushFilesAllowedUsers = [ 163, 384, 1180, 9890 ];

export const indexValues = [ 0,1,2,3,4,5,6 ];

export const enabledcategoryList = ["Error", "Verified", "Main Dental Home"];
export const enabledAptStatusList = ["Scheduled", "Complete", "Broken"];
export const enabledAptCategoryList = ["Error", "Verified", "Main Dental Home"];

export const enabledStatusCarrierColor = ["grey", "green", "red"];
export const woList = ["woPaid", "woAppealed", "woNotAppealed","woDenied","woPracticeError"];
export const woListUp = ["Wo Paid", "Wo Appealed", "Wo Not Appealed","Wo Denied","Wo Practice Error"];

export const woWeek = ["sat", "fri", "thu","wed","tue","mon"];
export const woWeekUp = ["Sat", "Fri", "Thu","Wed","Tue","Mon"];


export const finalInsurancecategoryList  = [{ins:"Error",color:["grey", "green", "red"]},
  {ins:"Verified",color:["grey", "green", "red"]},
  {ins:"Main Dental Home",color:["grey", "green", "red"]}];

export const statusList : any = {
  "Error":{grey:0,green:0,red:0},
  "Verified":{grey:0,green:0,red:0},
  "Main Dental Home":{grey:0,green:0,red:0},
};



export const practiceAdminColumnNames =[
  {name:"id", display:"Id"},
  {name:"practice", display:"Practice"},
  {name:"practiceDBName", display:"Practice DB Name"},
  {name:"practiceabbr", display:"Practice Abbr"},
  {name:"brand", display:"Brand"},
  {name:"sageEntityName", display:"Sage Entity Name"},
  {name:"enableDB", display:"Enable DB"},
  {name:"schedule", display:"Schedule"},
  {name:"ortho", display:"Ortho"},
  {name:"xrayService", display:"Xray Service"},
  {name:"alerts", display:"Alerts"},
  {name:"PCR", display:"PCR"},
  {name:"airPay", display:"AirPay"},
  {name:"isReachable", display:"Is Reachable"},

]

export const practiceAdminMoreColumnNames =[
  {name:"id", display:"Id"},
  {name:"practice", display:"Practice"},
  {name:"orthoLocation", display:"Ortho Location"},
  {name:"tab32ClinicID", display:"Tab32 Clinic ID"},
  {name:"uss", display:"USS"},
  {name:"broken", display:"Broken"},
  {name:"TAB32", display:"Tab32"},
  {name:"PracticeCommunicationConfig", display:"Practice Communication Config"},
]

export const practiceAdminDCRMColumnNames =[
  {name:"id", display:"Id"},
  {name:"practice", display:"Practice"},
  {name:"campaign", display:"Campaign"},
  {name:"mexicoPhone", display:"Mexico Phone"},
  {name:"textBack", display:"Text Back"},
  {name:"textMessage", display:"Text Message"},
  {name:"emailMessage", display:"Email Message"},
  {name:"emailSubject", display:"Email Subject"},
  {name:"textMessageSp", display:"Text Message Sp"},
  {name:"emailMessageSp", display:"Email Message Sp"},
  {name:"emailSubjectSp", display:"Email Subject Sp"},
  {name:"phone", display:"Phone"},
  {name:"referralTextBack", display:"Referral Text"},
  {name:"referralEmailBack", display:"Referral Email Back"},
  {name:"referralTextMessage", display:"Referral Text Message"},
  {name:"referralEmailMessage", display:"Referral Email Message"},
  {name:"referralEmailSubject", display:"Referral Email Subject"},
  {name:"referralTextMessageSp", display:"Referral Text Message Sp"},
  {name:"referralEmailMessageSp", display:"Referral Email Message Sp"},
  {name:"referralEmailSubjectSp", display:"Referral Email Subject Sp"},
  {name:"aptCreatedTextBack", display:"Apt Created Text Back"},
  {name:"aptCreatedEmailBack", display:"Apt Created Email Back"},
  {name:"aptCreatedTextMessage", display:"Apt Created Text Message"},
  {name:"aptCreatedEmailMessage", display:"Apt Created Email Message"},
  {name:"aptCreatedEmailSubject", display:"Apt Created Email Subject"},
  {name:"aptCreatedTextMessageSp", display:"Apt Created Text Message Sp"},
  {name:"aptCreatedEmailMessageSp", display:"Apt Created Email Message Sp"},
  {name:"aptCreatedEmailSubjectSp", display:"Apt Created Email Subject Sp"}
]

export const enabledCarrierList = ["MCNA", "DentaQuest", "TMHP", "UHC Medicaid"];
export const enabledCarrierColor = ["green", "redA", "redX", "noImage"];
export const finalInsurance = [{ins:"MCNA",color:["green", "redA", "redX", "noImage"]},
  {ins:"DentaQuest",color:["green", "redA", "redX", "noImage"]},
  {ins:"TMHP",color:["green", "redA", "redX", "noImage"]},
  {ins:"UHC Medicaid",color:["green", "redA", "redX", "noImage"]}];


export const unscheduledTreatmentObj :any = {
  'medicaid':'Medicaid',
  'chip':'CHIP',
  'cashUnder18':'Cash Under 18',
  'cash':'Cash over 18',
  'ppoUnder18': 'PPO under 18',
  'ppo':'PPO over 18',
  'cdp':'CDP Employee',
  'adultMedicaid':'Adult Medicaid',
}
export const practiceBrand :any = {'SMD': 'Smile Magic', 'FSD': 'Family Smiles'}
export const unscheduledTreatmentHeader = [
  'Practice Name', 'Exclude ProcedureCodes', 'Brand', 'Call Center Supported', 'Medicaid', 'Cash Under 18', 'Cash over 18',
  'PPO under 18', 'PPO over 18', 'Adult Medicaid', 'Total unscheduled appt '
];

export const providerList : any = {
  "DentaQuest" : "DENTA",
  "UHC Medicaid" : "UNITED HEALTH CARE"
};
export const insuranceList : any = {
    "MCNA" : {green:0,redA:0,redX:0,noImage:0},
    "DentaQuest" : {green:0,redA:0,redX:0,noImage:0},
    "TMHP" : {green:0,redA:0,redX:0,noImage:0,},
    "UHC Medicaid" : {green:0,redA:0,redX:0,noImage:0},
};

export const cardAppsMenu = [{
  key: 1,
  name: 'Sites',
  savePartnerUsage: true,
  redirect: true,
  icon: 'building',
  button: {
      redirect: 'sites',
      savePartnerUsage: myPartners.SITES,
      to: '',
      as: '',
      usageName: 'sites',
      grey: false,
      href: "",
      target: ''
  }
  
},{
  key: 9,
  name: 'TOOLS',
  savePartnerUsage: true,
  redirect: true,
  icon: 'wrench',
  button: {
      redirect: 'tools',
      savePartnerUsage: myPartners.DCRM,
      to: '',
      as: '',
      usageName: 'DCRM',
      grey: false,
      href: '',
      target: ''
  }
},{
  key: 0,
  name: 'Schedule',
  savePartnerUsage: true,
  redirect: true,
  icon: 'calendar alternate',
  button: {
      redirect: 'schedule',
      savePartnerUsage: myPartners.SCHEDULE,
      to: '',
      as: '',
      usageName: 'schedule',
      grey: false,
      href: '',
      target: ''
  }
  
},{
  key: 17,
  name: 'Alerts',
  savePartnerUsage: true,
  redirect: true,
  icon: 'bullhorn',
  button: {
      redirect: 'https://alerts.cdp.dental/alert',
      savePartnerUsage: myPartners.ALERTS,
      to: '',
      as: Link,
      usageName: 'Alerts',
      grey: false,
      href: '',
      target: ''
  }
  
},{
  key: 3,
  name: 'Leads',
  savePartnerUsage: true,
  redirect: true,
  icon: 'users',
  button: {
      redirect: 'leads',
      savePartnerUsage: myPartners.LEADS,
      to: '',
      as: '',
      grey: false,
      usageName: 'leads',
      href: '',
      target: ''
  }
  
},{
  key: 5,
  name: 'Admin',
  savePartnerUsage: true,
  icon: 'user',
  button: {
      redirect: '',
      savePartnerUsage: myPartners.ADMIN,
      to: '/admin/setup/manager-help',
      as: Link,
      grey: false,
      usageName: 'admin',
      href: '',
      target: ''
  }
}, {
  key: 6,
  name: 'Recruit',
  savePartnerUsage: true,
  redirect: true,
  icon: 'user',
  button: {
    redirect: 'recruiting',
    savePartnerUsage: myPartners.RECRUIT,
    to: '',
    as: '',
    grey: false,
    usageName: 'recruit',
    href: '',
    target: ''
  }
},
  {
    key: 28,
    name: 'LOUPES',
    subName:"Dr App",
    savePartnerUsage: true,
    redirect: true,
    icon: 'user md',
    customIcon:"udr-icon",
    button: {
      redirect: 'dentist',
      savePartnerUsage: myPartners.DENTIST,
      to: '',
      as: '',
      grey: false,
      usageName: 'dentist',
      href: '',
      target: ''
    }
},
{
  key: 21,
  name: 'Patient Finance',
  savePartnerUsage: true,
  redirect: true,
  icon: 'suitcase',
  button: {
      redirect: 'patient finance',
      savePartnerUsage: '',
      to: '',
      as: '',
      usageName: 'patient finance',
      grey: false,
      href: '',
      target: ''
  }
},{
  key: 22,
  name: 'Credentialing',
  savePartnerUsage: true,
  redirect: true,
  icon: 'hospital',
  button: {
    redirect: 'credentialing',
    savePartnerUsage: myPartners.CRED,
    to: '',
    as: '',
    grey: false,
    usageName: 'credentialing',
    href: '',
    target: ''
  },
  
},
{
  key:23,
  name: 'Scheduler',
  savePartnerUsage: true,
  redirect: true,
  icon:'clock outline',
  button:{
    redirect: 'scheduler',
    savePartnerUsage:myPartners.SCHEDULER,
    to:'',
    as:'',
    grey:false,
    usageName:'scheduler',
    href: '',
    target: ''
  }
},
{
  key:24,
  name: 'Claims Team',
  savePartnerUsage:true,
  icon:'users',
  redirect: true,
  button:{
    redirect:'billing',
    savePartnerUsage:myPartners.CLAIMS_TEAM,
    to:'',
    as:'',
    grey:false,
    usageName:'claims_team',
    href:'',
    target:''
  }
},
{
  key:25,
  name: 'Scheduling Department',
  savePartnerUsage:true,
  icon:'phone square',
  redirect: true,
  button:{
    redirect:'call_center',
    savePartnerUsage:myPartners.CALL_CENTER,
    to:'',
    as:'',
    grey:false,
    usageName:'call_center',
    href:'',
    target:''
  }
}, 
{
    key:26,
    name: 'Directory&OrgChart',
    savePartnerUsage:true,
    icon:'sitemap',
    button:{
      redirect:'',
      savePartnerUsage:myPartners.DIRECTORY_ORG_CHART,
      to:'/org-chart',
      as: Link,
      grey:false,
      usageName:'directory_org_chart',
      href:'',
      target:''
    }
  },
{
  key:29,
  name:'Referrals',
  savePartnerUsage:true,
  icon:'user plus',
  redirect: 'true',
  button:{
    redirect:'https://referout.cdp.dental/referrals',
    savePartnerUsage:myPartners.REFERRALS,
    to:'',
    as:'',
    grey:false,
    usageName:'referrals',
    href:'',
    target:''
    }
},
{
  key:30,
  name:'ION Task Uploader',
  icon:'clipboard list',
  button:{
    redirect: '',
    savePartnerUsage: myPartners.MONDAY_APP,
    to: '/ion-tasks',
    as: Link,
    usageName: 'ion-tasks',
    grey: false,
    href: '',
    target: ''
  }
},
{
  key: 31,
  name: 'SPORE',
  savePartnerUsage: true,
  icon: 'users',
  button: {
    redirect: '',
    savePartnerUsage: myPartners.SPORE,
    to: '/sporeService',
    as: Link,
    grey: false,
    usageName: 'spore',
    href: '',
    target: ''
  }
},
{
  key: 16,
  name: 'Patient Validation',
  savePartnerUsage: true,
  icon: 'user secret',
  button: {
    redirect: '',
    savePartnerUsage: myPartners.PT_VALIDATION,
    to: '/patients/patient-validation',
    as: Link,
    usageName: 'PT_VALIDATION',
    grey: false,
    href: '',
    target: ''
  }
},
  {
    key:33,
    name:'Dental CRM',
    savePartnerUsage:true,
    icon:'industry',
    redirect: false,
    button:{
      redirect:'',
      savePartnerUsage:myPartners.DENTAL_CRM,
      to:'',
      as:'',
      grey:false,
      usageName:'dentalCRM',
      href:'https://dcrm.cdp.dental/leads',
      target:'_blank'
    }
  },
  {
    key:36,
    name:'Websites',
    savePartnerUsage:true,
    icon:'globe',
    button:{
      redirect:'websites',
      savePartnerUsage:myPartners.WEBSITES,
      to:'/aws/websites',
      as:Link,
      grey:false,
      usageName:'websites',
      href:'',
      target:''
    }
  },
  {
    key:38,
    name:'APTOOD',
    savePartnerUsage:true,
    icon:'patreon',
    redirect: 'true',
    button:{
      redirect:'APTOOD',
      savePartnerUsage:myPartners.APTOOD,
      to:'',
      as:'',
      grey:false,
      usageName:'aptood',
      href:'',
      target:''
    }
  },
  {
    key:39,
    name:'HEALTH STATUS',
    savePartnerUsage:true,
    icon:'chart line',
    button:{
      redirect:'',
      savePartnerUsage:myPartners.HEALTH_STATUS,
      to:'/health-status',
      as: Link,
      grey:false,
      usageName:'healthstatus',
      href:'',
      target:''
    }
  },
  {
    key:40,
    name:'OpenDental Insurance',
    savePartnerUsage:true,
    icon:'heartbeat',
    button:{
      redirect:'',
      savePartnerUsage:myPartners.OPENDENTAL_INSURANCE,
      to:'/open-dental-insurance/file-upload',
      as: Link,
      grey:false,
      usageName:'open-dental-insurance/file-upload',
      href:'',
      target:''
    }
  },
  {
    key:41,
    name:'OD User',
    savePartnerUsage:true,
    icon:'user plus',
    redirect: 'true',
    button:{
      redirect:'https://oduser.cdp.dental/',
      savePartnerUsage:myPartners.ODUSER,
      to:'',
      as:'',
      grey:false,
      usageName:'oduser',
      href:'',
      target:''
    }
  },
  {
    key:42,
    name:'AirPay Patients',
    savePartnerUsage:true,
    icon:'user',
    button:{
      redirect:'',
      savePartnerUsage:myPartners.AIRPAY_PATIENTS,
      to:'/air-pay/patients',
      as: Link,
      grey:false,
      usageName:'air-pay/patients',
      href:'',
      target:''
    }
  },
  ];

export const cardPagesMenu = [{
  key: 11,
  name: 'CSR',
  savePartnerUsage: true,
  icon: 'file alternate',
  button: {
    redirect: '',
    savePartnerUsage: myPartners.CSR,
    to: '/clinical-scheduling-report',
    as: Link,
    usageName: 'CSR',
    grey: false,
    href: '',
    target: ''
  }

},{
  key: 4,
  name: 'Patient Care Report',
  savePartnerUsage: true,
  icon: 'file alternate',
  button: {
    redirect: '',
    savePartnerUsage: myPartners.PCR,
    to: '/patient-care-report/pcr-splashboard',
    as: Link,
    usageName: 'PCR',
    grey: false,
    href: '',
    target: ''
  }

},
{
  key: 8,
  name: 'SPOT',
  savePartnerUsage: true,
  icon: 'users',
  button: {
    redirect: '',
    savePartnerUsage: myPartners.SPOT,
    to: '/spot/support-patient-opportunity-tool',
    as: Link,
    usageName: 'spot',
    href: '',
    target: ''
  }

},
{
  key: 18,
  name: 'Practice Manager Dashboard',
  savePartnerUsage: true,
  icon: 'dashboard',
  button: {
    redirect: '',
    savePartnerUsage: myPartners.PMD,
    to: '/practice-manager-dashboard',
    as: Link,
    usageName: 'pmd',
    grey: false,
    href: '',
    target: ''
  }
},
  {
    key: 22,
    name: 'Specialty PCO',
    savePartnerUsage: true,
    icon: 'cog',
    button: {
      redirect: '',
      savePartnerUsage: myPartners.PCO,
      to: '/specialty-pco',
      as: Link,
      usageName: 'specialty-pco',
      grey: false,
      href: '',
      target: ''
    }
  },
  {
    key:44,
    name:'Executive Care Metrics',
    savePartnerUsage:true,
    icon:'eye',
    redirect: false,
    button:{
      redirect:'',
      savePartnerUsage:myPartners.EXEC_CARE_METRICS,
      to:'',
      as:'',
      grey:false,
      usageName:'execCareMetrics',
      href:'https://cdp.rocks/ecm',
      target:'_blank'
    }
  },
];

export const HelpPages: any = {
  1: { name: "UDR - SCHEDULE" },
  2: { name: "UDR - CARE" },
  3: { name: "UDR - Care Delta" },
  4: { name: "UDR - Future Care Review" },
  6: { name: "UDR - USAGE" },
  7: { name: "UDR - EXTERNALSITES" },
  9: { name: "UDR - DOCS", totalChild: 3 },
  10: { name: "UDR - Dentists View", parent: 9 },
  11: { name: "UDR - Admin List View", parent: 9 },
  12: { name: "UDR - Document List View", parent: 9 },
  13: { name: "UDR - REPORTS", totalChild: 4 },
  14: { name: "UDR - Care", parent: 13 },
  15: { name: "UDR - Adjustments", parent: 13 },
  16: { name: "UDR - Writeoffs", parent: 13 },
  17: { name: "UDR - Standard of Care", parent: 13 },
  18: { name: "UDR - PRACTICES" },
  19: { name: "UDR - OVERVIEW" },
  20: { name: "UDR - MOBILE" },
  21: { name: "UDR - TimeOff Requests" },
  22: { name: "UDR - Doc View By Dentist" },
  23: { name: "UDR - Manage My Profile" },
  24: { name: "UDR - bGOAL < = > bCARE" },
  25: { name: "UDR - bGOAL < = > bCARE REPORT" },
  26: { name: "UDR - BUDGET BGOAL" },
  27: { name: "UDR - CARE DELTA REPORT" },
  28: { name: "UDR - TIME OFF REQUEST SUMMARY" },
  29: { name: "UDR - OFFERED WORK DAYS" },
  30: { name: "UDR - BITEWING REPORT NOTES" },
  31: { name: "UDR - CLINICAL CONSTITUTION"},
  32: { name: "UDR - powerBI"},
  33: { name: "UDR - Cdp", totalChild: 2},
  34: { name: "UDR - Ortho", totalChild: 2},
  301: { name: "UDR - CLINICAL CONSTITUTION DASHBOARD"},
  302: { name: "UDR - CLINICAL - Clinical Excellence", totalChild: 1},
  303: { name: "UDR - CLINICAL - Clinical Excellence - Todo Reviews", parent: 302},
  35: { name: "CDP Pay", parent: 33},
  36: { name: "CDP Reports", parent: 33},
  37: { name: "Ortho Pay", parent: 34},
  38: { name: "Ortho Reports", parent: 34},
  39: { name: "MY.CDP - Patient Validation"},
  40: { name: "MY.CDP - Adjustment", totalChild: 2},
  41: { name: "MY.CDP - Adjustment - Setup", parent: 40},
  42: { name: "MY.CDP - Adjustment Acknowledgement", parent: 40},
  43: { name: "MY.CDP - Five9 List"},
  44: { name: "MY.CDP - OS PCR"},
  45: { name: "MY.CDP - MDHM"},
  46: { name: "MY.CDP - CSR"},
  47: { name: "MY.CDP - Patient Care Report"},
  48: { name: "MY.CDP - Practice Manager Dashboard"},
  49: { name: "MY.CDP - SPORE"},
  50: { name: "MY.CDP - SPORE Notifications"},
  60: { name: "Credentialing - List Of Providers" },
  61: { name: "Credentialing - Provider Application" },
  62: { name: "Credentialing - Provider Documents" },
  63: { name: "Credentialing - My Files > Provider View" },
  64: { name: "Credentialing - My Files > Admin List View" },
  65: { name: "Credentialing - My Files > Document List View" },
  66: { name: "Credentialing - My Files > Doc View By Dentist" },
  67: { name: "Credentialing - My Files > Wall Documents" },
  68: { name: "Credentialing - My Files > Wall Documents ALT" },
  69: { name: "Credentialing - Provider Roster" },
  70: { name: "Credentialing - Practice Information" },
  71: { name: "Credentialing - Textract" },
  72: { name: "Credentialing - Practice View" },
  80: { name: "Recruitment - Recruiting" },
  81: { name: "Recruitment - Tasks" },
  82: { name: "Recruitment - Setup > Lead Status" },
  83: { name: "Recruitment - Setup > School" },
  84: { name: "Recruitment - Setup > Leads Interest" },
  85: { name: "Recruitment - Setup > Tasks" },
  86: { name: "Recruitment - Setup > Funnel Status" },
  87: { name: "Recruitment - Setup > Process Status" },
  88: { name: "Recruitment - Setup > Current Dentist" },
  89: { name: "Recruitment - Setup > Potential Dentist" },
  90: { name: "Recruitment - Setup > How you heard" },
  91: { name: "Recruitment - Setup > Email" },
  92: { name: "Recruitment - Setup > Tags" },
  93: { name: "Recruitment - Events" },
  94: { name: "Recruitment - Calendar" },
  95: { name: "Recruitment - Activity Feed" },
  96: { name: "Recruitment - Dr Movement" },
  // 97: { name: "Recruitment - Quicksights" },
  98: { name: "Recruitment - Usage" },
  100: { name: "CDP Alerts - Alerts" },
  101: { name: "CDP Alerts - Setup" },
  102: { name: "CDP Alerts - Usage" },
  110: { name: "Referrals - All Referrals" },
  111: { name: "Referrals - Setup > Ortho > Procedure Codes" },
  112: { name: "Referrals - Setup > Ortho > Status Setup" },
  113: { name: "Referrals - Setup > Ortho > Notification" },
  114: { name: "Referrals - Setup > Oral Surgery > Procedure Codes" },
  115: { name: "Referrals - Setup > Oral Surgery > Status Setup" },
  116: { name: "Referrals - Setup > Oral Surgery > Notification" },
  117: { name: "Referrals - Reports" },
  118: { name: "Referrals - Usage" },
  121: { name: "CDP LEADS - new Leads" },
  122: { name: "CDP LEADS - Recare Patients" },
  140: { name: "CDP SCHEDULE - Reports > OPEN CLOSED MISMATCH REPORT" },
  141: { name: "CDP SCHEDULE - Reports > TS/TE MISMATCH REPORT" },
  142: { name: "CDP SCHEDULE - Reports > DOCTOR WORK HISTORY REPORT" },
  143: { name: "CDP SCHEDULE - Dentists Substitute Costs" },
  144: { name: "CDP SCHEDULE - Usage" },
  150: { name: "BILLING - PatientFinance > Credit Cards to Run" },
  151: { name: "BILLING - PatientFinance> Setup > Setup Actions" },
  152: { name: "BILLING - PatientFinance> Setup > Setup Status" },
  153: { name: "BILLING - PatientFinance> Setup > Setup Assignments" },
  154: { name: "BILLING - PatientFinance> Setup > Setup QA Status" },
  155: { name: "BILLING - PatientFinance> Setup > Setup Xcharge" },
  156: { name: "BILLING - PatientFinance> Reports > Daily Payments Report"},
  157: { name: "BILLING - PatientFinance > Collection Reports" },
  158: { name: "BILLING - PatientFinance> PayPlan QA Issues" },
  159: { name: "BILLING - Usage Report" },
  160: { name: "BILLING - Claims" },
  161: { name: "BILLING - Production v Collection" },
  162: { name: "SITES - Practices " },
  163: { name: "SITES - Admin" },
  164: { name: "SITES - Responsibility Matrix" },
  165: { name: "SITES - Usage" },
  166: { name: "SITES - Practice Table" },
  167: { name: "SITES - Practice Holidays" },
    168: { name: "ADMIN - Users" },
    169: { name: "ADMIN - Provider Profile" },
    170: { name: "ADMIN - Xray Service Dashboard" },
    171: { name: "ADMIN - User Matrix" },
    172: { name: "ADMIN - Manual Uploads > Patient Count And Care" },
    173: { name: "ADMIN - Goal > PCR Goal" },
    174: { name: "ADMIN - Goal > Oral Surgery Goal" },
    175: { name: "ADMIN - SOFD > Re Run PCR" },
    176: { name: "ADMIN - SOFD > MCO Login Report" },
    177: { name: "ADMIN - SOFD > Available Api List" },
    178: { name: "ADMIN - SOFD > Crons" },
    179: { name: "ADMIN - SOFD > Lambdas" },
    180: { name: "ADMIN - SOFD > DCRM Automation Status" },
    181: { name: "ADMIN - SOFD > MCO Scrapper" },
    182: { name: "ADMIN - SOFD > Adjustment List" },
    183: { name: "ADMIN - SOFD > Write Off List" },
    184: { name: "ADMIN - SOFD > Email Configurations" },
    185: { name: "ADMIN - SETUP > Practice" },
    186: { name: "ADMIN - SETUP > MCO" },
    187: { name: "ADMIN - SETUP > Regions" },
    188: { name: "ADMIN - SETUP > Carrier Identity" },
    189: { name: "ADMIN - SETUP > Procedure Code Change" },
    190: { name: "ADMIN - SETUP > GOX Precedure Code" },
    191: { name: "ADMIN - SETUP > GOZ Precedure Code" },
    192: { name: "ADMIN - SETUP > Manager Help" },
    193: { name: "ADMIN - SETUP > Leap Edit" },
    194: { name: "ADMIN - SETUP > Spot Commlog Search" },
    195: { name: "ADMIN - SETUP > Notification Emails" },
    196: { name: "ADMIN - SETUP > Brands" },
    197: { name: "ADMIN - SETUP > Educator Notifications" },
    198: { name: "ADMIN - SETUP > Ramp Threshold Defaults" },
    199: { name: "ADMIN - SETUP > Hygienist Bracket System" },
    200: { name: "ADMIN - SETUP > Dr App Permission Matrix" },
    201: { name: "ADMIN - SETUP > Sage Accounts Description" },
    202: { name: "ADMIN - SETUP > Implant Cost" },
    203: { name: "ADMIN - SETUP > Provider Earnings Report" },
    204: { name: "ADMIN - SETUP > Financial Note Category" },
    205: { name: "ADMIN - USAGE" },
    206: { name: "MEMBERS - DASHBOARD" },
    207: { name: "MEMBERS - RTP1" },
    208: { name: "MEMBERS - Call Recordings" },
    209: { name: "MEMBERS - New Patient Origin" },
    210: { name: "MEMBERS - Contact Data Report" },
    211: { name: "MEMBERS - Quality Index Report" },
    212: { name: "MEMBERS - Priority Status Report" },
    213: { name: "MEMBERS - Lead Data Report" },
    214: { name: "MEMBERS - Members" },
    215: { name: "MEMBERS - Members Status Report" },
    216: { name: "MEMBERS - RTP 4" },
    217: { name: "MEMBERS - EVA" },
    218: { name: "MEMBERS - ADMIN" },
    219: { name: "MEMBERS - ADMIN" },
    220: { name: "MEMBERS - PP Patient Details" },
    221: { name: "MEMBERS - USAGE" },
    222: { name: "MEMBERS - DELETE APPOINTMENTS > By Group" },
    223: { name: "MEMBERS - DELETE APPOINTMENTS > Summery" },
    224: { name: "MEMBERS - DELETE APPOINTMENTS > Details" },
    225: { name: "ADMIN - SOFD > Logins" },
    226: { name: "APTOOD - API Configuration" },
    227: { name: "APTOOD - Patients" },
    228: { name: "APTOOD - Patients Logs" },
    229: { name: "ADMIN - SETUP > Ortho Provider Earnings Report" },
    230: { name: "Credentialing - Document Form" },
    231: { name: "UKG - Payrolls" },
    232 :{name :"SPOT - SUPPORT PATIENT OPPORTUNITY TOOL"}
}

export const pmdexception = "Could not retreive information for selected practice, please select another practice"
export const acceptHelpFileTypes = ".mp4, .pdf";

export const csvHeaderPMD = {Practicename: "PRACTICE NAME",
ScheduledProd: "SCHEDULED PROD",
LWDProd: "LWD PROD",
TodayProd: "TODAY PROD",
LWDPTCount: "LWD PT COUNT" ,
TodaysGoal: "TODAYS GOAL",
GLCPV: "GL CPV",
ACTCPV: "ACT CPV",
DeltaCPV: "DELTA CPV",
SchAptToday: "SCH APT TODAY",
PTSeenToday:"PT SEEN TODAY",
ExpectedShow: "EXPECTED SHOW",
PTGoalToday: "PT GOAL TODAY",
NotBilled: "NOT BILLED",
NotBilledProc: "NOT BILLED PROC",
NPWorkNow: "NP WORK NOW",
RcNoFutureWork: "RC NO FUTURE WORK",
APTCreatedNext4Days: "APT CREATED NEXT 4 DAYS",
NoFuturewtx: "NO FUTURE WTX",
APTCreatedGoal: "APT CREATED GOAL",
NoFutureApt: "NO FUTURE APT",
LeadsEnteredToday: "LEADS ENTERED TODAY",
LeadsWorkedToday: "LEADS WORKED TODAY",
LeadsWonToday: "LEADS WON TODAY",
LeadsLostToday: "LEADS LOST TODAY",
TasksRollOver: "TASKS ROLLOVER",
TasksToday: "TASKS TODAY"};

export const csvHeaderPMD_NoFutureApt = {Practice: "Practice",
PatNum: "PatNum",
LastVisit: "Last Visit",
LastApt: "Last Apt",
NextApt: "Next Apt" ,
TxFee: "TxFee"};

export const csvHeaderPMD_TodayProdDetail = {Practice: "Practice",
PatNum: "PatNum",
ProcDate: "ProcDate",
ProcCode: "ProcCode",
ProcFee: "ProcFee"};

export const csvHeaderPMD_TodayGoalAll = {practice: "Practice",
todaysgoal: "Todays Goal",
totalbudget: "Total Goal",
MTDGoal: "MTD Goal",
mtdcare: "MTD Care",
MTDGoal_To_Act_care: "MTD Goal To Actual Care",
workdaysTotal: "Workdays Total",
workdaysLeft: "Workdays Left",
totalshifts: "Total Shifts",
totalsShiftsLeft: "Total Shifts Left"};

export const PCR_PopupTableHeardings = [
{
  uicodekey: 'SCHEDULED PATIENTS',
  tableheardings: {col1:{name:"Appointment Number",key:"AptNum"},
                    col2:{name:"Patient Number",key:"PatNum"},
                    col3:{name:"Visit Type",key:"visitType"},
                    col4:{name:"Appointment Date Time",key:"AptDateTime"},
                    col5:{name:"Last Exam",key:"lastExam"},
                    col6:{name:"First Visit Date",key:"firstVisit"},
                    col7:{name:"Days Since LAst Exam",key:"daysSinceLastExam"},
                    col8:{name:"",key:""},
                    col9:{name:"",key:""},
                    col10:{name:"",key:""},
                    col11:{name:"",key:""},
                  }
},
  {
    uicodekey: 'SHOW RATE',
    tableheardings: {
      col1:{name:"Patient Number",key:"PatNum"},
      col2:{name:"Date",key:"aptDate"},
      col3:{name:"Operatory",key:"opName"},
      col4:{name:"Appointment Status",key:"AptStatusName"},
      col5:{name:"Apt Visit Type",key:"visitType"},
      col6:{name:"",key:""},
      col7:{name:"",key:""},
      col8:{name:"",key:""},
      col9:{name:"",key:""},
      col10:{name:"",key:""},
      col11:{name:"",key:""}
    }
  },
{
  uicodekey: 'COMPLETED PATIENTS',
  tableheardings: {col1:{name:"Abbr",key:"abbr"},
                    col2:{name:"Patient Number",key:"patientId"},
                    col3:{name:"Visit Type",key:"visitType"},
                    col4:{name:"Date Of Service",key:"dateOfService"},
                    col5:{name:"Price",key:"price"},
                    col6:{name:"Age",key:"age"},
                    col7:{name:"Date Month",key:"dateMonth"},
                    col8:{name:"Age Group",key:"ageGroup"},
                    col9:{name:"Carrier Name",key:"carrierName"},
                    col10:{name:"Insurance Type",key:"insuranceType"},
                    col11:{name:"",key:""}
                  }
},
  {
    uicodekey: 'Work Day',
    tableheardings: {col1:{name:"Date",key:"date"},
      col2:{name:"Status",key:"status"},
      col3:{name:"",key:""},
      col4:{name:"",key:""},
      col5:{name:"",key:""},
      col6:{name:"",key:""},
      col7:{name:"",key:""},
      col8:{name:"",key:""},
      col9:{name:"",key:""},
      col10:{name:"",key:""},
      col11:{name:"",key:""}
    }
  },
{
  uicodekey: 'ACT PT MTD',
  tableheardings: {col1:{name:"Provider",key:"provider"},
                    col2:{name:"Patient Number",key:"patientId"},
                    col3:{name:"Visit Type",key:"visitType"},
                    col4:{name:"Date Of Service",key:"dateOfService"},
                    col5:{name:"Care",key:"price"},
                    col6:{name:"Age",key:"age"},
                    col7:{name:"Date Month",key:"dateMonth"},
                    col8:{name:"Pt visit count",key:"visitPtCount"},
                    col9:{name:"Age Group",key:"ageGroup"},
                    col10:{name:"Carrier Name",key:"carrierName"},
                    col11:{name:"Insurance Type",key:"insuranceType"},
                  }
},
  {
    uicodekey: 'INSURANCE',
    tableheardings: {col1:{name:"Provider",key:"provider"},
      col2:{name:"Patient Number",key:"patientId"},
      col3:{name:"Visit Type",key:"visitType"},
      col4:{name:"Date Of Service",key:"dateOfService"},
      col5:{name:"Care",key:"price"},
      col6:{name:"Age",key:"age"},
      col7:{name:"Date Month",key:"dateMonth"},
      col8:{name:"Pt visit count",key:"visitPtCount"},
      col9:{name:"Age Group",key:"ageGroup"},
      col10:{name:"Carrier Name",key:"carrierName"},
      col11:{name:"Insurance Type",key:"insuranceType"},
    }
  },
  {
    uicodekey: 'AGE MIX',
    tableheardings: {col1:{name:"Provider",key:"provider"},
      col2:{name:"Patient Number",key:"patientId"},
      col3:{name:"Visit Type",key:"visitType"},
      col4:{name:"Date Of Service",key:"dateOfService"},
      col5:{name:"Care",key:"price"},
      col6:{name:"Age",key:"age"},
      col7:{name:"Date Month",key:"dateMonth"},
      col8:{name:"Pt visit count",key:"visitPtCount"},
      col9:{name:"Age Group",key:"ageGroup"},
      col10:{name:"Carrier Name",key:"carrierName"},
      col11:{name:"Insurance Type",key:"insuranceType"},
    }
  },
  {
    uicodekey: 'Work Day',
    tableheardings: {col1:{name:"Date",key:"date"},
      col2:{name:"Status",key:"status"},
      col3:{name:"",key:""},
      col4:{name:"",key:""},
      col5:{name:"",key:""},
      col6:{name:"",key:""},
      col7:{name:"",key:""},
      col8:{name:"",key:""},
      col9:{name:"",key:""},
      col10:{name:"",key:""},
      col11:{name:"",key:""}
    }
  },
    {
        uicodekey: 'ACT ALL PT MTD',
        tableheardings: {col1:{name:"Provider",key:"provider"},
            col2:{name:"Patient Number",key:"patientId"},
            col3:{name:"Visit Type",key:"visitType"},
            col4:{name:"Date Of Service",key:"dateOfService"},
            col5:{name:"Care",key:"price"},
            col6:{name:"Age",key:"age"},
            col7:{name:"Date Month",key:"dateMonth"},
            col8:{name:"Pt visit count",key:"visitPtCount"},
            col9:{name:"Age Group",key:"ageGroup"},
            col10:{name:"Carrier Name",key:"carrierName"},
            col11:{name:"Insurance Type",key:"insuranceType"},
        }
    },
  {
    uicodekey: 'InsuranceMix',
    tableheardings: {col1:{name:"Provider",key:"provider"},
      col2:{name:"Patient Number",key:"patientId"},
      col3:{name:"Visit Type",key:"visitType"},
      col4:{name:"Date Of Service",key:"dateOfService"},
      col5:{name:"Care",key:"price"},
      col6:{name:"Age",key:"age"},
      col7:{name:"Date Month",key:"dateMonth"},
      col8:{name:"Pt visit count",key:"visitPtCount"},
      col9:{name:"Age Group",key:"ageGroup"},
      col10:{name:"Carrier Name",key:"carrierName"},
      col11:{name:"Insurance Type",key:"insuranceType"},
    }
  },
  {
  uicodekey: 'LWD',
  tableheardings: {col1:{name:"Abbr",key:"abbr"},
                    col2:{name:"Patient Number",key:"patientId"},
                    col3:{name:"Visit Type",key:"visitType"},
                    col4:{name:"Date Of Service",key:"dateOfService"},
                    col5:{name:"Goal",key:"price"},
                    col6:{name:"Age",key:"age"},
                    col7:{name:"Date Month",key:"dateMonth"},
                    col8:{name:"",key:""},
                    col9:{name:"",key:""},
                    col10:{name:"",key:""},
                    col11:{name:"",key:""},
                  }
},
  {
    uicodekey: 'adjustment',
      tableheardings: {
      col1:{name:"",key:" "},
      col2:{name:"Patient ID",key:"patientId"},
      col3:{name:"Adjustment Code",key:"adjustmentCode"},
      col4:{name:"Date Of Service",key:"dateOfService"},
      col5:{name:"Price",key:"adjustment"},
      col6:{name:"",key:""},
      col7:{name:"Comment",key:"comment"},
      col8:{name:"",key:""},
      col9:{name:"",key:""},
      col10:{name:"",key:""},
      col11:{name:"",key:""},
      }
},
{
  uicodekey: 'ptseentoday',
  tableheardings: {col1:{name:"PatNum",key:"PatNum"},
                    col2:{name:"ProcDate",key:"ProcDate"},
                    col3:{name:"ProcCode",key:"ProcCode"},
                    col4:{name:"ProcFee",key:"ProcFee"},
                    col5:{name:"",key:""},
                    col6:{name:"",key:""},
                    col7:{name:"",key:""},
                    col8:{name:"",key:""},
                    col9:{name:"",key:""},
                    col10:{name:"",key:""},
                    col11:{name:"",key:""},
  }
 }

];

export type InsuranceCategory={
  key: string, totalVisit: number,bCare:number
}

export const cronStatus = [
  {
    text: 'All',
    value: 'All',
    key : '0'
  }, {
    text: 'In Progress',
    value: 0,
    key : '1'
  }, {
    text: 'Done',
    value: 1,
    key : '2'
  }, {
    text: 'Failed',
    value: 2,
    key : '3'
  }
];

export const CronStatusTxt = ['In Progress','Done', 'Failed'];

export const typeOfOptions = [
  {key:"START", text:"Start", value:"S-TART"},
  {key:"SDSTART", text:"SD-Start", value:"SDSTART"},
  {key:"SDCONTRACT", text:"SD-Contract", value:"SDCONTRACT"},
  {key:"DEBOND", text:"Debond", value:"DEBOND"},
  {key: "DOT", text: "DOT", value: "DOT"},
  {key:"PATIENT", text:"Patient", value:"PATIENT"},
  {key:"EXAMS", text:"Exams", value:"EXAMS"},
  {key:"CONSULT" , text:"Consult" , value:"CONSULT"},
  {key:"CONTRACT" , text:"Contract" , value:"CONTRACT"},
  {key:"RECALL" , text:"Recall" , value:"RECALL"},
  {key:"N/A", text:"N/A", value:"N/A"}
];

export const csvHeaderOrthoPCRPopUp = {PatientID: "PatientID",
ProcDate: "ProcDate",
Procfee: "Procfee",
Next_Apt: "Next_Apt",
Abbr: "Abbr" ,
PatientTotalDue: "PatientTotalDue",
AdjAmt: "AdjAmt",
ProcCode: "ProcCode",
ContractTotal: "ContractTotal",
typeOf: "typeOf"};

export const csvHeaderOrthoPCR = {Practice: "Practice",
  patients: "Patients",
  Ortho_Start: "Ortho_Start",
  Debond: "Debond",
  SD_Start: "SD_Start",
  SD_Contract: "SD_Contract",
  Work_Days: "Work_Days",
  Total_Starts: "Total_Starts",
  Total_Contracts: "Total_Contracts"};


export const dataNotLoaded = "Data could not be retrieved. Please try again later.";
export const dataNotLoadedPMD = "Loading data. If you continue seeing this message, please try again later.";

export const todaygoal = "todaygoal";
export const fvgoal = "fv";
export const rcgoal = "rc";
export const txgoal = "tx";

export const scheduletodaygoal = "scheduletodaygoal";
export const schedulefvgoal = "schedulefv";
export const schedulercgoal = "schedulerc";
export const scheduletxgoal = "scheduletx";
export const projectedcare = "projectedcare";
export const totalProjectedcare = "totalProjectedcare";
export const projectedcareDr = "projectedcareDr";

export const pttodaygoal = "pttodaygoal";

export const todaygoalcss = `<td style=
'
max-width: 2000px;
zoom: 65%;
width: auto;
overflow: hidden;
word-wrap: break-word;
font-size: 10px;
white-space:nowrap;'`;

export const OSPracticesReportTypes = [
  {
    key: 0,
    title: 'This table shows office patient count and % referrals age 15+  Current Month',
    period: 'current month'
  },
  // {
  //   key: 1,
  //   title: 'This table shows office patient count and % referrals age 15+  Prior Three Month',
  //   period: 'last month'
  // },
  // {
  //   key: 2,
  //   title: 'This table shows office patient count and % referrals age 15+ trailing 90 days',
  //   period: 'trailing 90 days'
  // },
  // {
  //   key: 3,
  //   title: 'This table shows office patient count and % referrals age 15+ trailing 12 months',
  //   period: 'trailing 12 months'
  // },

]
export const OSPracticesReportTypesInfo=["This table shows office patient count and % referrals age 15+  Current Month","This table shows office patient count and % referrals age 15+  Prior Three Month"]
export const appointmentStatus=["None","Scheduled","Complete","UnschedList","ASAP", "Broken","Planned","PtNote","PtNoteCompleted"]
export const CONSULT_ProcCodes=['P1', 'PIR','P1AR','P1A','P1T'];
export const FUTURECONSULT_ProcCodes=['P1', 'PIR','P1AR','P1A','P1T'];
export const CONTRACTS_ProcCodes=['P2', 'P2ND','P2,P1','P2,P1R','P2-EXP/BOND','P2-REF','P2SD,P1','P2SD,P100B','P2SD,P1AR','P2SD,P45B','P2,P2SD'];
export const SD_ProcCodes=['P2SD', 'P2,P2SD'];
export const START_ProcCodes=['P3','P3-IND','P3,P1','P3-INV','P3,P11','P3,P-IMP-SCAN','P2,P3','P23,P3'];
export const SDSTART_ProcCodes=['P3SD', 'P3SD,P1','P3SD,P11','P3SD,P2','P3SD,P3','P3SD,P-IMP-SCAN','P3SD-IND','P3,P3SD','P2,P3SD','P2SD,P3SD'];
export const PTS_ProcCodes=['Active'];
export const DEBOND_ProcCodes=['RET-20', 'INV-DEBOND','P44','P44A/B'];
export const DOT_ProcCodes=['P1R'];
export const RecallPT_ProcCodes=['P1R'];
export const YTD="ytd";
export const MTD="mtd";
export const ortho_production="production";
export enum FilterOptions {
  PracticeFilter="practiceFilter",
  DateFilter="dateFilter"
}
export const PCR_KEYS = 'fdgwVtghrt5345gfdgfd653245235232v2rcra';
export const AdminImpDataSaveUpdMsg = 'Form data submitted successfully';
export const AdminImpDelMsg = 'Form data hide update successfully';

export const defaultReport = 'jeff.gosling@cdp.dental';

export const defaultToDenton = 'denton';


export const PMDInitialState: any = {
  pmrm:{mgrFirstName : '', mgrLastName : '', regMgrFirstName: '', regMgrLastName : '', practiceName : ''},
  filters: {
    practice: '',
    regionManager: {value:''}
  },
  tableData: [],
  defaultSettings: {
    practice: '',
    regionManager: {value:''}
  },
  mco_npw: '',
  mco_rcn: '',
  pmdtaskrollover: null,
  pmdtasktoday: null,
  pmdtasktotal: null,
  pmdnotbilled$: null,
  pmdnotbillerproc: null,
  FvPtPatientBudget: 0,
  RcPtPatientBudget: 0 ,
  TxPtPatientBudget: 0,
  monthlyGoal: 0 ,
  perShiftGoal: 0 ,
  bdppp: 0,
  actppp: 0,
  bCare_perpractice:0,
  bCare_totalVisit:0,
  bCare_deltappp:0,
  general_shifts:[],
  hygienists_shifts:[],
  pmdDailyCareSummary:[],
  FV_PT_today: 0,
  FV_PT_yesterday: 0,
  FV_PT_twoDaysAgo: 0,
  FV_CPV_today: 0,
  FV_CPV_yesterday: 0,
  FV_CPV_twoDaysAgo: 0,
  RC_PT_today: 0,
  RC_PT_yesterday: 0,
  RC_PT_twoDaysAgo: 0,
  RC_CPV_today: 0,
  RC_CPV_yesterday: 0,
  RC_CPV_twoDaysAgo: 0,
  TX_PT_today: 0,
  TX_PT_yesterday: 0,
  TX_PT_twoDaysAgo: 0,
  TX_CPV_today: 0,
  TX_CPV_yesterday: 0,
  TX_CPV_twoDaysAgo: 0,
  Days_Complete:0,
  Days_Left:0,
  General_Shifts_Left:0,
  General_Shifts_Complete:0,
  Hygiene_Shifts_Left:0,
  Hygiene_Shifts_Complete:0,
  Care:0,
  leads_entered:0,
  leads_won:0,
  leads_worked:0,
  leads_lost:0,
  apt_created_next_four_days:null,
  apt_created_goal:0,
  no_future_w_tax:0,
  no_future_apt:0,
  currentDayGoal:0,
  callcenter_data: {},
  scheduleToday30DayApts:0,
  scheduleToday30DayCompletd:0,
  scheduleTodayPlanScheduledApts:0,
  schAptToday:0,
  schAptTodayData: null,
  ptSeenToday:0,
  procFeeSum:0,
  aptfill_today:0,
  aptfill_yesterday:0,
  aptfill_twoDaysAgo:0,
  schedulePMDDataOptions:[],
  isMobile: "ui three stackable cards",
  progresscsv:0,
  pmdScheduleCoordinatorNoFutureWtX_Data:[],
  pmdScheduleCoordinatorNoFutureApt_Data:[],
  pmdDailyPTSeenSummary:[],
  pmdProductionTodaysProdDetail:[],
  pmdPtSeenTodayDetail:[],
  pmdLWDate:0,
  pmdProductionLWDProdDetail:[],
  procFeeSumLWD:0,
  lwdptCount:0,
  pmdlwdptCountDetail:[],
  todaysgoal:0,
  pmdCollections: null,
  pmdWriteOffs: null,
  pmdAdjustments: null,
  pmdNonConfirmedApts: null,
  openingHours: {},
  report: { budgetData: {}, reportData: { byPractice: {}, doctorPracticesObj: {} } },
  syncdatetime:"",
  showcountdown:false
};

export const applicationList =[{text: 'Select Application', value: 'all', key: 'All'},
  {text: "Sites", value: "Sites", key: "Sites"},
 {text: "Tools", value: "Tools", key: "Tools"},
{text: "Schedule", value: "Schedule", key: "Schedule"},
{text: "Billing", value: "Billing", key: "Billing"},
{text: "Billing-ClaimsAgedSummary", value: "Billing-ClaimsAgedSummary", key: "Billing-ClaimsAgedSummary"},
{text: "Billing-Credits", value: "Billing-Credits", key: "Billing-Credits"},
{text: "Billing-Usage", value: "Billing-Usage", key: "Billing-Usage"},
{text: "Billing-Collection-ProductvCollection", value: "Billing-Collection-ProductvCollection", key: "Billing-Collection-ProductvCollection"},
{text: "Billing-PatienceFinance-CollectionReports", value: "Billing-PatienceFinance-CollectionReports", key: "Billing-PatienceFinance-CollectionReports"},
{text: "Billing-PatientFinance-PayPlanQAIssues", value: "Billing-PatientFinance-PayPlanQAIssues", key: "Billing-PatientFinance-PayPlanQAIssues"},
{text: "Billing-PatientFinance-Reports-DailyPaymentReports", value: "Billing-PatientFinance-Reports-DailyPaymentReports", key: "Billing-PatientFinance-Reports-DailyPaymentReports"},
{text: "Billing-PatientFinance-Setup-SetupActions", value: "Billing-PatientFinance-Setup-SetupActions", key: "Billing-PatientFinance-Setup-SetupActions"},
{text: "Billing-PatientFinance-Setup-SetupStatus", value: "Billing-PatientFinance-Setup-SetupStatus", key: "Billing-PatientFinance-Setup-SetupStatus"},
{text: "Billing-PatientFinance-Setup-SetupPPLQA", value: "Billing-PatientFinance-Setup-SetupPPLQA", key: "Billing-PatientFinance-Setup-SetupPPLQA"},
{text: "Billing-PatientFinance-Setup-PatientFinanceSetupAssignments", value: "Billing-PatientFinance-Setup-PatientFinanceSetupAssignments", key: "Billing-PatientFinance-Setup-PatientFinanceSetupAssignments"},
{text: "Billing-PatientFinance-Setup-SetupXCharge", value: "Billing-PatientFinance-Setup-SetupXCharge", key: "Billing-PatientFinance-Setup-SetupXCharge"},
{text: "Alerts", value: "Alerts", v: "Alerts"},
{text: "Alerts-Setup", value: "Alerts-Setup", v: "Alerts-Setup"},
{text: "Alerts-Usage", value: "Alerts-Usage", v: "Alerts-Usage"},
{text: "Leads", value: "Leads", key: "Leads"},
{text: "Admin", value: "Admin", key: "Admin"},
{text: "Admin-UKG-Payrolls", value: "Admin-UKG-Payrolls", key: "Admin-UKG-Payrolls"},
{text: "Admin-UKG-PayStatements", value: "Admin-UKG-PayStatements", key: "Admin-UKG-PayStatements"},
{text: "Recruit", value: "Recruit", key: "Recruit"},
{text: "Loupes - bCare", value: "Loupes - bCare", key: "Loupes - bCare"},
{text: "Loupes - CDP PAY", value: "Loupes - CDP PAY", key: "Loupes - CDP PAY"},
  {text: "Loupes - SMILES PAY", value: "Loupes - SMILES PAY", key: "Loupes - SMILES PAY"},
  {text: "Loupes - CDP Sage", value: "Loupes - CDP Sage", key: "Loupes - CDP Sage"},
{text: "Loupes - CDP UKG", value: "Loupes - CDP UKG", key: "Loupes - CDP UKG"},
{text: "Loupes - SMILES Sage", value: "Loupes - SMILES Sage", key: "Loupes - SMILES Sage"},
{text: "Loupes - SMILES UKG", value: "Loupes - SMILES UKG", key: "Loupes - SMILES UKG"},
{text: "Patient Finance", value: "Patient Finance", key: "Patient Finance"},
{text: "Credentialing", value: "Credentialing", key: "Credentialing"},
{text: "Scheduler", value: "Scheduler", key: "Scheduler"},
{text: "Claims Team", value: "Claims Team", key: "Claims Team"},
{text: "Scheduling Department", value: "Scheduling Department", key: "Scheduling Department"},
{text: "Referrals", value: "Referrals", key: "Referrals"},
{text: "Members", value: "Members", key: "Members"},
{text: "SPORE", value: "SPORE", key: "SPORE"},
{text: "Patient Validation", value: "Patient Validation", key: "Patient Validation"},
{text: "PCR", value: "PCR", key: "PCR"},
{text: "SPOT", value: "SPOT", key: "SPOT"},
{text: "SPOT-Patients", value: "SPOT-Patients", key: "SPOT-Patients"},
{text: "SPOT-UsageReportSpot", value: "SPOT-UsageReportSpot", key: "SPOT-UsageReportSpot"},
{text: "SPOT-UnscheduledTreatment", value: "SPOT-UnscheduledTreatment", key: "SPOT-UnscheduledTreatment"},
{text: "Practice Manager Dashboard", value: "Practice Manager Dashboard", key: "Practice Manager Dashboard"},
{text: "Websites", value: "Websites", key: "Websites"}];

export const myCDPApplicationList =[
"Patient Validation",
"PCR",
"Practice Manager Dashboard",];

export const downTimeTrackerStatusList =[{text: 'Select Status', value: 'all', key: 'All'},
  {text: "Open", value: "Open", key: "Open"},
 {text: "Scheduled", value: "Scheduled", key: "Scheduled"},
  {text: "Closed", value: "Closed", key: "Closed"}]

export const howWasItRepoted =[{text: "Select Option", value: "", key:"selectOption"},
  {text: "User", value: "User", key: "User"}, 
  {text: "SOFD Team", value: "SOFD Team", key: "SOFDTeam"},
]

export const notificationGroupList=[{text: "Select Group", value: "", key: "selectGroup"},
  {text: "PM/RM", value: "PM/RM", key: "PMRM"}, 
{text: "Claims", value: "Claims", key: "Claims"},
{text: "Support", value: "Support", key: "Support"}, 
{text: "Scheduling Department", value: "Scheduling Department", key: "Scheduling Department"},
{text: "Recent Users", value: "Recent Users", key: "Recent Users"},
]

export const errorSourceList=[{text: "Select Source", value: "", key: "selectSource"},
  {text: "External", value: "External", key: "External"}, 
{text: "Internal", value: "Internal", key: "Internal"},
]

export const mco = [{key:'MCNA' ,value:'MCNA',text:'MCNA'},
  {key:'DENTA' ,value:'DENTA' , text:'DENTA'},
  {key:'TMHP' ,value:'TMHP', text:'TMHP'},
  {key:'UHC',value:'UNITED HEALTH CARE',text:'UNITED HEALTH CARE'},
  {key:'SPORE' ,value:'SPORE', text:'SPORE'},
  {key:'DentalXChange' ,value:'DentalXChange' , text:'DentalXChange'}
]

export const brand =  [{key:0 ,value:'Smile Magic',text:'Smile Magic'},
  {key:1 ,value:'Family Smiles' , text:'Family Smiles'},
  {key:2 ,value:'OrthoDent', text:'OrthoDent'},
  {key:3,value:'3C Dental',text:'3C Dental'},
  {key:4,value:'SDD',text:'SDD'}]

export const brandABBR =  [{key:0 ,value:'SMD',text:'Smile Magic'},
  {key:1 ,value:'FSD' , text:'Family Smiles'},
  {key:2 ,value:'OTD', text:'OrthoDent'},
  {key:3,value:'ACD',text:'3C Dental'},
  {key:4,value:'SYN',text:'SYN'},
  {key:4,value:'SDD',text:'SDD'}]

export const csvHeaderPayStatement = {
  Id: "Id",
  Hours: "Hours",
  BaseRate: "BaseRate",
  EEAmountGross: "EEAmountGross",
  PayDate: "PayDate",
  DateRangeFrom: "DateRangeFrom",
  DateRangeTo: "DateRangeTo"
};

export const omitPractices = ['Victoria Kids', 'Crossroads', 'DAL NW HWY']
export const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;



