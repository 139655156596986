import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Template from "./Template";
import {RootState} from "../model";
import {setPractices} from "../utils/common";
import {useActions} from "../redux/actions";
import * as practiceActions from "../redux/actions/practice"
import {Button, Dropdown, Form, Grid, Input, Modal, Table} from "semantic-ui-react";
import {Account} from "../model/user";
import * as patientsActions from "../redux/actions/patients"
import {getAllPractices} from "../redux/actions/practice";


interface Props {
    user: Account
}

function AirPayPatients(props: Props) {

    const practiceAction = useActions(practiceActions);
    const patientsAction = useActions(patientsActions);

    const initialState: any = {
        practiceOpts: [],
        patients : [],
        emailSent: false
    };

    const initialForm: any = {
        practice: null,
        subscriberId: null
    };

    const [state, setState] = useState(initialState);
    const [inputData, setInputData] = useState(initialForm);
    const {user} = props;

    useEffect(() => {
        practiceAction.getAllPractices().then((practices: any) => {
            let practiceList: any = [{key: 'all', value: '', text: 'Select Practice'}];
            practices.forEach((item: any) => {
                practiceList.push({
                    key: item.id,
                    value: item.practiceDBName,
                    text: item.practice
                })
            })
            setState({...state, practiceOpts: practiceList})
        })
    }, []);


    const handleSubmit = () => {
        patientsAction.getAirPayPatients(inputData).then((res: any) => {
            setState({...state, patients: res})
        })
    }
    const handleSendScreenShots = (patient: any) => {
        patientsAction.sendPdfFilesToUser(user.email, patient.date, patient.subscriberId)
            .then(() => {
                setState({...state, emailSent : true})
            })
    }

    const generateReport = (report: any) => {
        return report && report.map((item: any) => {
            return (
                <Table.Row key={item.id}>
                    <Table.Cell>{item.practice}</Table.Cell>
                    <Table.Cell>{item.subscriberId}</Table.Cell>
                    <Table.Cell>{item.date}</Table.Cell>
                    <Table.Cell>{item.filePushed ? 'Yes' : 'No'}</Table.Cell>
                    <Table.Cell>{item.fileStatusAbbr}</Table.Cell>
                    <Table.Cell>{item.eligible ? 'Yes' : 'No'}</Table.Cell>
                    <Table.Cell><a onClick={()=> handleSendScreenShots(item)}>Email Me</a></Table.Cell>
                </Table.Row>
            )
        })
    }

    return (
        <Template activeLink='air-pay-patients'>

            <Modal
                open={state.emailSent}
                onClose={()=> setState({...state, emailSent: false})}
                aria-labelledby="ModalHeader"
                centered={true}
                closeIcon={true}
                size={'large'}
            >
                <Modal.Header>
                    Email Sent
                </Modal.Header>
                <Modal.Content scrolling={true}>
                    An email with attachment has been sent to your registered email Id, Please check your inbox.
                </Modal.Content>
            </Modal>

            <div className="ui card">
                <div className="content">
                    <h2 className="float-left mr10">AirPay Patients</h2>
                </div>
            </div>
            <div className="ui card">
                <div className="content">
                    <Grid columns={3} divided={true}>
                        <Grid.Row>
                            <Grid.Column>
                                <Form>
                                    <Form.Field>
                                        <label>Practice</label>
                                        <Dropdown
                                            placeholder='Select Practice'
                                            options={state.practiceOpts}
                                            selection={true}
                                            search={true}
                                            onChange={(e, values) => setInputData({
                                                ...inputData,
                                                practice: values.value
                                            })}
                                            name="practice"
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Subscriber ID</label>
                                        <Input
                                            placeholder='Subscriber ID'
                                            onBlur={(e: any) => setInputData({
                                                ...inputData,
                                                subscriberId: e.target.value
                                            })}
                                        />
                                    </Form.Field>

                                    <Button onClick={() => handleSubmit()}>Submit</Button>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </div>
            <div className={"ui card"}>
                <Table celled={true}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Practice</Table.HeaderCell>
                            <Table.HeaderCell>Subscriber ID</Table.HeaderCell>
                            <Table.HeaderCell>Date Of Service</Table.HeaderCell>
                            <Table.HeaderCell>Pushed to OD</Table.HeaderCell>
                            <Table.HeaderCell>Status ABBR</Table.HeaderCell>
                            <Table.HeaderCell>Eligibility</Table.HeaderCell>
                            <Table.HeaderCell>Screen Shots</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {generateReport(state.patients)}
                    </Table.Body>
                </Table>
            </div>


        </Template>
    );
}

function mapStateToProps(state: RootState) {
    return {
        user: state.auth.user
    };
}

export default connect(mapStateToProps)(AirPayPatients)