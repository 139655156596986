import React, { useState } from 'react';
import { Table, Button, Icon, Dimmer, Loader, Label, Popup, Modal } from 'semantic-ui-react';

interface CronEmailSettings {
    id: number;
    cronId: number;
    cronTitle: string;
    description: string;
    emailGroups: string[];
    lastRun?: string;
    status?: 'active' | 'inactive';
    group: string;
}

interface Props {
    data: CronEmailSettings[];
    isLoading: boolean;
    onEdit: (cron: CronEmailSettings) => void;
    onDelete: (id: number) => void;
}

function CronEmailTable({ data, isLoading, onEdit, onDelete }: Props) {
    const [showAllEmails, setShowAllEmails] = useState<{show: boolean, emails: string[]}>({
        show: false,
        emails: []
    });

    const renderEmailCell = (emails: string[]) => {
        if (emails.length <= 2) {
            return emails.join(', ');
        }

        return (
            <>
                <span>{`${emails.slice(0, 2).join(', ')} `}</span>
                <Button
                    size='mini'
                    compact
                    basic
                    onClick={() => setShowAllEmails({ show: true, emails })}
                >
                    +{emails.length - 2} more
                </Button>
            </>
        );
    };

    return (
        <div className="FreezeTable">
            <Table className="ui table table-striped celled table-hover unstackable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Cron Title</Table.HeaderCell>
                        <Table.HeaderCell>Group Name</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        <Table.HeaderCell>Email Recipients</Table.HeaderCell>
                        <Table.HeaderCell>Last Run</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {isLoading ? (
                        <Table.Row>
                            <Table.Cell colSpan={7}>
                                <Dimmer active inverted>
                                    <Loader>Loading</Loader>
                                </Dimmer>
                            </Table.Cell>
                        </Table.Row>
                    ) : (
                        data.map((cron) => (
                            <Table.Row key={cron.id}>
                                <Table.Cell>{`${cron.cronId} - ${cron.cronTitle}`}</Table.Cell>
                                <Table.Cell>{cron.group}</Table.Cell>
                                <Table.Cell>{cron.description}</Table.Cell>
                                <Table.Cell>
                                    {renderEmailCell(cron.emailGroups)}
                                </Table.Cell>
                                <Table.Cell>{cron.lastRun || 'Never'}</Table.Cell>
                                <Table.Cell>
                                    <Icon 
                                        name={cron.status === 'active' ? 'check circle' : 'times circle'} 
                                        color={cron.status === 'active' ? 'green' : 'red'}
                                    />
                                    {cron.status}
                                </Table.Cell>
                                <Table.Cell>
                                    <Button.Group size='mini'>
                                        <Button icon onClick={() => onEdit(cron)}>
                                            <Icon name='edit' />
                                        </Button>
                                        <Button icon negative onClick={() => onDelete(cron.id)}>
                                            <Icon name='trash' />
                                        </Button>
                                    </Button.Group>
                                </Table.Cell>
                            </Table.Row>
                        ))
                    )}
                </Table.Body>
            </Table>

            <Modal
                size='tiny'
                open={showAllEmails.show}
                onClose={() => setShowAllEmails({ show: false, emails: [] })}
            >
                <Modal.Header>Email Recipients</Modal.Header>
                <Modal.Content>
                    <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        {showAllEmails.emails.map((email, index) => (
                            <div key={index} style={{ padding: '4px 0' }}>
                                <Icon name='mail' /> {email}
                            </div>
                        ))}
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setShowAllEmails({ show: false, emails: [] })}>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
}

export default CronEmailTable; 