
import { Action, ActionType } from "../../model/actionTypes";
import createReducer from "./createReducer";


const initialState = { data: [] }
export const trackHealthFails = createReducer(initialState, {
    [ActionType.HEALTH_FAILS](state: any, action: Action<any>) {
      return {
        ...state,
        data: [...state.data, action.payload], 
      };
    },
  });