import { ActionType } from "../../model/actionTypes";
import {PatientFilters, PatientValidationRun, MDHMconfirmation} from "../../model/patients";
import {errorHandler} from "./auth";
import moment from "moment";
import { sortBy } from "../../utils/common";
import {checkUndefinedAptStatus, correctAptStatus} from "../../constants";
import { bulkProcessesAPI } from "../configure";

export const getPatients = (params: PatientFilters, reload: Boolean,patientData:any) => async (dispatch: Function, getState: Function, api: any) => {
    let stringifyParams;
    if(params){
        stringifyParams = '/'+JSON.stringify(params)
    }
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.get("patientValidation/getPatients"+stringifyParams);
        if (params.keyword.length) {
            if(params.keyword.length !== 0){
                dispatch({ type: ActionType.PATIENTS_VALIDATION, payload: {filteredPatientData: resp} });
                dispatch({ type: ActionType.LOADER, payload: false });
                return;
            }else{
                dispatch({ type: ActionType.PATIENTS_VALIDATION, payload: {filteredPatientData: patientData} });
            }

        }
        dispatch(filterPatientData(params, reload, resp));
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const getAirPayPatients = (params: object) => async (dispatch: Function, getState: Function, api: any) => {
    let stringifyParams;
    if(params){
        stringifyParams = '/'+JSON.stringify(params)
    }
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.get("patientValidation/airpay/patients"+stringifyParams);
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return;
    }
}

export const sendPdfFilesToUser = (email: string, date: string, subscriberId: string) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.post(`patientValidation/airpay/sendFiles`, {email, date, subscriberId});
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return;
    }
}



export const filterPatientData = (params: PatientFilters, reload: Boolean, patientResponse: any) => async (dispatch: Function, getState: Function) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    const defaultPractice = getState().patients.defaultPractice;
    let practices: any = [];

    if (getState().practice.practiceNamesMap && defaultPractice.value && defaultPractice.value !== 'all') {
        const dbPractices = defaultPractice.value.split(",") || [];
        dbPractices.forEach((dbPractice: any) => {
            if (getState().practice.practiceNamesMap[dbPractice]) {
                const { practice } = getState().practice.practiceNamesMap[dbPractice];
                practices.push(practice);
            }
        });
    }

    let patientData: any = {};
    let filteredPatientData: any = {};

    if (reload) {
        let locations = params.locations!==null? params.locations : ['all'];
        locations.forEach((name: string) => {
            const responseData = patientResponse[name] || [];
            filteredPatientData[name] = Array.isArray(responseData) ? responseData : [responseData];
        });

        params.locations = locations;
        params.practices = practices;

        dispatch({ 
            type: ActionType.PATIENTS_VALIDATION, 
            payload: { filteredPatientData, patientData: patientResponse, filters: params } 
        });
        dispatch({ type: ActionType.LOADER, payload: false });
    } else {
        const filters = getState().patients.filters;
        const carrierList = getState().patients.carrierIdentity;
        let data: any = [];

        let selectedCarrierList: any = [];
        filters.insurance.forEach((value: string) => {
            let dataArr = carrierList.filter((carrier: any) => carrier.modifyCarrierName.trim() === value.trim());
            selectedCarrierList = selectedCarrierList.concat(dataArr);
        });

        filters.locations.forEach((name: string) => {
            const practiceData = patientResponse[name] || [];
            let objArr: any = [];

            practiceData.forEach((row: any) => {
                if (selectedCarrierList.length) {
                    const isFound = selectedCarrierList.find((carrier: any) => 
                        carrier.carrierName === row.CarrierName || carrier.modifyCarrierName === row.CarrierName
                    );
                    if (isFound) {
                        const item = filterWithStatus(filters, row);
                        if (item) {
                            objArr.push(item);
                        }
                    }
                } else {
                    objArr.push(row);
                }
            });

            if (!data[name]) {
                data[name] = [];
            }
            
            data[name] = data[name].concat(objArr);
        });

        dispatch({ 
            type: ActionType.PATIENTS_VALIDATION, 
            payload: { filteredPatientData: data, patientData: patientResponse } 
        });
        dispatch({ type: ActionType.LOADER, payload: false });
        return;
    }

    return patientData;
};

export const onFiltersChange = (filters: any) => async (dispatch: Function, getState: Function) => {
    let patientValidationData = getState().patients.patientData;
    const carrierList = getState().patients.carrierIdentity;
    let data: any = [];
    let selectedCarrierList: any = []
    filters.insurance.forEach((value: string) => {
        let dataArr = carrierList.filter((carrier: any) => carrier.modifyCarrierName.trim() === value.trim());
        selectedCarrierList = selectedCarrierList.concat(dataArr);
    })
    filters.locations.forEach((name: string) => {
        const practiceData = patientValidationData[name];
        let objArr: any = []
        practiceData.forEach((row:any) => {
            const carrierName = row.CarrierName ? row.CarrierName.toLowerCase() : ''
            if (selectedCarrierList.length) {
                const isFound = selectedCarrierList.find((carrier: any) => carrier.carrierName.toLowerCase() === carrierName || carrier.modifyCarrierName.toLowerCase() === carrierName);
                if (isFound) {
                    const item = filterWithStatus(filters, row);
                    item && objArr.push(item);
                }
            } else {
                const item = filterWithStatus(filters, row);
                item && objArr.push(item);
            }
        })
       
        if (!data[name]) {
            data[name] = [];
        }
                
        data[name] = data[name].concat(objArr);
    })
    dispatch({ type: ActionType.PATIENTS_VALIDATION, payload: {filteredPatientData: data, filters: filters} });
    return data;
}

const filterWithStatus = (filters: any, row: any) => {
    let returnVal = true;

    if (filters.status !== 'all') {
        if(row.status !== filters.status){
            returnVal = false;
        }
    }

    if(filters.aptStatus !== 'all'){
        if(filters.aptStatus === 'undefined' && checkUndefinedAptStatus(row.APT_Status)){
            returnVal = true;
        }
        else if(correctAptStatus(row.APT_Status).toLowerCase() !== filters.aptStatus){
            returnVal = false;
        }
    }
    if(filters.brand && filters.brand.length==1&&filters.brand.includes("All")){
    }else{
        if(filters.brand &&filters.brand.length>=1&& !filters.brand.includes(row.brand)){
            returnVal = false;
        }
    }    

    if (filters.filePushed !== 'all') {     
        if (Object.prototype.hasOwnProperty.call(row, 'filePushed')) {
            
            if(row.filePushed !== filters.filePushed){
                returnVal = false;
            }
        }
        else if(String(filters.filePushed) !=='Blank'){
            returnVal = false;
        }
        
    }

    if(returnVal){
        return row;
    }
}

export const getPdfFile = (params: object) => async (dispatch: Function, getState: Function, api: any) => {
    let stringifyParams;
    if(params){
        stringifyParams = '/'+JSON.stringify(params)
    }
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.get("patientValidation/getPdf"+stringifyParams);
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return;
    }
};

export const performPushPdf = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await bulkProcessesAPI.get("patientValidation/pushOpenDentalPdf",null,null);
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return;
    }
}

export const runValidation = (payload : PatientValidationRun) => async (dispatch: Function, getState: Function, api: any) => {

    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  bulkProcessesAPI.post("patientValidation/runValidation", payload,null);
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const runAllValidation = (data : any) => async (dispatch: Function, getState: Function, api: any) => {
    await bulkProcessesAPI.post("patientValidation/runAllValidation", data,null);
    return;
};

export const  runVerifyInsValidation= (data:any, patientData: any) => async (dispatch: Function, getState: Function, api: any) => {
    
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await bulkProcessesAPI.put("patientValidation/runVerifyValidation", data,null);
        dispatch({ type: ActionType.PATIENTS_VALIDATION, payload: {filteredPatientData: Object.assign({}, patientData)} });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const insuranceCategory = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.get("patientValidation/insuranceCategory");
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}

export const getCarrierIdentity = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const resp =  await api.get("patientValidation/carrierIdentity");
        dispatch({type: ActionType.CARRIER_IDENTITY, payload: {carrierIdentity: resp}})
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch(err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}

export const addMDHMconfirmation = (payload : MDHMconfirmation, isPTValidation:boolean) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const resp =  await api.post("patientValidation/addMDHMconfirmation", payload);
        if (isPTValidation) {
            if (payload.type === 'error') {
                let patientData = getState().patients.filteredPatientData;
                const user = getState().auth.user;
                const practiceData = patientData[payload.practice];
                const index = practiceData.findIndex((item: any) => item.PatNum === payload.patNum);
                if (index >= 0) {
                    const row = practiceData[index];
                    let mdhm = row.MDHM;
                    mdhm.description = payload.description;
                    mdhm.date = payload.date;
                    mdhm.status = payload.status;
                    mdhm.users = {id: user.id, firstName: user.firstname, lastName: user.lastname, email: user.email}
                    practiceData.slice(index, 1, mdhm);
                    patientData = Object.assign(patientData, {[payload.practice]: practiceData})
                    dispatch({
                        type: ActionType.PATIENTS_VALIDATION,
                        payload: {filteredPatientData: Object.assign({}, patientData)}
                    });
                }
            }
            if (payload.type === 'home') {
                let patientData = getState().patients.filteredPatientData;
                const user = getState().auth.user;
                const practiceData = patientData[payload.practice];
                const index = practiceData.findIndex((item: any) => item.PatNum === payload.patNum);
                if (index >= 0) {
                    const row = practiceData[index];
                    let home = row.home;
                    home.communicationMode = payload.communicationMode
                    home.description = payload.description;
                    home.date = payload.date;
                    home.users = {id: user.id, firstName: user.firstname, lastName: user.lastname, email: user.email}
                    practiceData.slice(index, 1, home);
                    patientData = Object.assign(patientData, {[payload.practice]: practiceData})
                    dispatch({
                        type: ActionType.PATIENTS_VALIDATION,
                        payload: {filteredPatientData: Object.assign({}, patientData)}
                    });
                }
            }
        }
        return resp;
    } catch(err) {
        return dispatch(errorHandler(err));
    }
}

export const getMDHMconfirmation = (params: object) => async (dispatch: Function, getState: Function, api: any) => {
    let stringifyParams;
    if(params){
        stringifyParams = '/'+JSON.stringify(params)
    }
    try {
        const resp =  await api.get("patientValidation/getMDHMconfirmation"+stringifyParams);
        dispatch({ type: ActionType.MDHM_CONFIRMATION, payload: resp });
        return resp;
    } catch(err) {
        return dispatch(errorHandler(err));
    }
}

export const getErrorReport = (params: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    let stringifyParams;
    if(params){
        params.startDate = moment(params.date).clone().startOf('month').format('YYYY-MM-DD');
        params.endDate = moment(params.date).clone().endOf('month').format('YYYY-MM-DD');
        stringifyParams = '/'+JSON.stringify(params);
        delete params.date
    }
    try {
        const resp =  await api.get("patientValidation/getErrorReport"+stringifyParams);
        dispatch({ type: ActionType.FETCH_PATIENT_VALIDATION_ERROR_REPORT, payload: {patientValidationErrorReport: resp} });
        dispatch({type: ActionType.LOADER, payload: false});
        return resp;
    } catch(err) {
        dispatch({type: ActionType.LOADER, payload: false});
        return dispatch(errorHandler(err));
        
    }
}

export const downloadAllFiles = (data : any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        await bulkProcessesAPI.post(`patientValidation/sendDownloadLink`,data,null);
        dispatch({ type: ActionType.LOADER, payload: false });
    } catch (e) {
        dispatch({ type: ActionType.LOADER, payload: false });
    }
}

export const sendMailPCRPopupDetail = (params:any) => async (dispatch: Function, getState: Function, api: any) => {
    try {       
        let pmdCSVobj:any[] = [];

        params.data = sortBy(params.data,params.col1Key);

        if(params.col8Name && params.col8Key){

            pmdCSVobj.push({Practice: "Practice",
                [params.col1Name]: params.col1Key,
                [params.col2Name]: params.col2Key,
                [params.col3Name]: params.col3Key,
                [params.col4Name]: params.col4Key,
                [params.col5Name]: params.col5Key,
                [params.col6Name]: params.col6Key,
                [params.col7Name]: params.col7Key,
                [params.col8Name]:params.col8Key,
                [params.col9Name]:params.col9Key,
                [params.col10Name]:params.col10Key,
                [params.col11Name]:params.col11Key,
            });

        }else if(params.col7Name === 'comment'){
            pmdCSVobj.push({Practice: "Practice",
                [params.col2Name]: params.col2Key,
                [params.col3Name]: params.col3Key,
                [params.col4Name]: params.col4Key,
                [params.col5Name]: params.col5Key,
                [params.col6Name]: params.col6Key,
                [params.col7Name]: params.col7Key,
            });

        }else{

            pmdCSVobj.push({Practice: "Practice",
                [params.col1Name]: params.col1Key,
                [params.col2Name]: params.col2Key,
                [params.col3Name]: params.col3Key,
                [params.col4Name]: params.col4Key,
                [params.col5Name]: params.col5Key,
                [params.col6Name]: params.col6Key,
                [params.col7Name]: params.col7Key,
            });
        }




        for (const item of params.data) {
                if(params.col8Name && params.col8Key){
                    pmdCSVobj.push({Practice: params.practicename? params.practicename : item.practice ? item.practice : 'All',
                        [params.col1Name]: item[params.col1Key],
                        [params.col2Name]: item[params.col2Key],
                        [params.col3Name]: item[params.col3Key],
                        [params.col4Name]: item[params.col4Key],
                        [params.col5Name]: item[params.col5Key],
                        [params.col6Name]: item[params.col6Key],
                        [params.col7Name]: item[params.col7Key],
                        [params.col8Name]: item[params.col8Key],
                        [params.col9Name]: item[params.col9Key],
                        [params.col10Name]:item[params.col10Key],
                        [params.col11Name]:item[params.col11Key],
                    });

                }else if(params.col7Name === 'comment'){
                    pmdCSVobj.push({Practice: params.practicename? params.practicename : item.practice ? item.practice : 'All',
                        [params.col2Name]: item[params.col2Key],
                        [params.col3Name]: item[params.col3Key],
                        [params.col4Name]: item[params.col4Key],
                        [params.col5Name]: item[params.col5Key],
                        [params.col6Name]: item[params.col6Key],
                        [params.col7Name]: item[params.col7Key],
                    });

                }else{
                    pmdCSVobj.push({Practice: params.practicename?params.practicename : item.practice ?  item.practice : 'All',
                        [params.col1Name]: item[params.col1Key],
                        [params.col2Name]: item[params.col2Key],
                        [params.col3Name]: item[params.col3Key],
                        [params.col4Name]: item[params.col4Key],
                        [params.col5Name]: item[params.col5Key],
                        [params.col6Name]: item[params.col6Key],
                        [params.col7Name]: item[params.col7Key]
                    });
                }
                pmdCSVobj.forEach(function(v){ delete v.undefined });
            }
        if (pmdCSVobj.length) {

            params.data = pmdCSVobj;
            let params2 ={practicename:params.practicename ? params.practicename : 'All',popupTitle:params.popupTitle,data:params.data}
            params = null;
            params = params2;
            await api.post(`report/sendMailPCRPopupDetail`,params);
            dispatch({ type: ActionType.LOADER, payload: false });
            return true;
        }
	} catch (err) {
        console.log(err);
        return dispatch({ type: ActionType.LOADER, payload: false });
	}
}