import { ActionType } from "../../model/actionTypes";

export const getCronEmailSettings = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    const resp = await api.get("crons/email-settings/get");
    dispatch({ type: ActionType.LOADER, payload: false });
    return resp;
}

export const createCronEmailSettings = (cronEmailSettings: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    const resp = await api.post("crons/email-settings/create", cronEmailSettings);
    dispatch({ type: ActionType.LOADER, payload: false });
    return resp;
}

export const getActiveCrons = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    const resp = await api.get("crons/email-settings/active-crons");
    dispatch({ type: ActionType.LOADER, payload: false });
    return resp;
}

export const updateCronEmailSettings = (cronEmailSettings: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    const resp = await api.put(`crons/email-settings/update`, cronEmailSettings);
    dispatch({ type: ActionType.LOADER, payload: false });
    return resp;
}

export const deleteCronEmailSettings = (id: number) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    const resp = await api.delete(`crons/email-settings/delete/${id}`);
    dispatch({ type: ActionType.LOADER, payload: false });
    return resp;
}