import React from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

interface CronOption {
    id: number;
    title: string;
}

interface Props {
    value?: number;
    onChange: (value: number | null) => void;
    crons: CronOption[];
}

function CronSelect({ value, onChange, crons }: Props) {
    const options = crons.map(cron => ({
        key: cron.id,
        value: cron.id,
        text: `${cron.id} - ${cron.title}`
    }));

    const handleChange = (_: React.SyntheticEvent, data: DropdownProps) => {
        onChange(data.value as number || null);
    };

    return (
        <Dropdown
            placeholder='Select Cron'
            fluid
            search
            selection
            options={options}
            value={value}
            onChange={handleChange}
        />
    );
}

export default CronSelect; 