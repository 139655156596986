import React, { useEffect, useState } from 'react';
import { AccordionTitle, AccordionContent, Icon, Grid, GridRow, GridColumn, Button, ButtonGroup, Loader, Progress } from 'semantic-ui-react'
import * as HealthStatusActions from "../../redux/actions/healthStatus";
import { useActions } from '../../redux/actions';
import AptoodTable from './AptoodTable';
import ProgressBar from './ProgressBar';
import { TABS } from './healthStatusUtils';
const _ = require('underscore');

function AptoodConfigHealth() {
    const initialState = { loading: false, configs: { count: 0, data: [] }, panel: '', counts: { success: 0, failed: 0, pending: 0 }, records: [] as any };
    const healthStatusActions = useActions(HealthStatusActions);
    const [state, setState] = useState(initialState);
    const { configs, loading, panel, records, counts } = state;
    const prefix = TABS.APTOOD;

    useEffect(() => {
        setState({ ...state, loading: true })
        loadData();
    }, [])

    const loadData = () => {
        healthStatusActions.getAptoodConfigHealth().then((response: any) => {
            const { data } = response;
            const failed = data.filter((o: any) => o.error);
            const counts = { success: data.length - failed.length, failed: failed.length, pending: 0 };
            healthStatusActions.addToCount(counts, { tab: prefix, items: failed.map((m: any) => ({ m, ...m.practice })), mapBy: 'practice' }).then();
            setState({ ...state, configs: response, counts, records: data, loading: false });
        })
    }

    const onSelect = (selected: string) => {
        if (panel === selected)
            setState({ ...state, panel: '' });
        else
            setState({ ...state, panel: selected });
    }


    const viewRecords = (failed: boolean) => {
        if (failed)
            setState({ ...state, configs: { count: configs.count, data: records.filter((o: any) => o.error) } })
        else
            setState({ ...state, configs: { count: configs.count, data: records.filter((o: any) => !o.error) } })
    }

    const manuallyRerunLambdaFunction = () => {
        setState({ ...state, loading: true })
        healthStatusActions.manuallyRerunLambdaFunction()
            .then((response: any) => {
                loadData();
            })
            .catch((error: any) => {
                setState({ ...state, loading: false })
            });
    }

    return (
        <>
            <AccordionTitle active={state.panel === prefix} onClick={() => onSelect(prefix)}>
                <Grid>
                    <GridRow>
                        <GridColumn width={13} >
                            <Icon name='dropdown' />
                            <span style={{ fontSize: '15px', marginRight: '30px' }}>APTOOD</span>
                            {loading && <Loader active size='tiny' inline='centered' />}
                        </GridColumn>
                        <GridColumn width={3}>
                            <ProgressBar failed={counts.failed} pending={counts.pending} success={counts.success} />
                        </GridColumn>
                    </GridRow>
                </Grid>
            </AccordionTitle>
            <AccordionContent active={panel === prefix}>
                {!loading && <Grid>
                    <GridRow>
                        <GridColumn width={16}>
                            <ButtonGroup >
                                <Button onClick={() => viewRecords(false)} icon='folder outline' color='green' content={`Passed ${(counts.success)}`} />
                                <Button onClick={() => viewRecords(true)} labelPosition='right' icon='folder outline' color='red' content={`Failed ${(counts.failed)}`} />
                            </ButtonGroup>
                            <Button onClick={() => manuallyRerunLambdaFunction()} icon='refresh icon' labelPosition='right' primary content={`Manually Rerun`} className={'right'} />

                            <AptoodTable data={configs.data} />
                        </GridColumn>
                    </GridRow>
                </Grid>}
            </AccordionContent>
        </>
    );
}

export default AptoodConfigHealth;