import React from "react";
import { Button, Grid, GridColumn, Header, Icon } from "semantic-ui-react";

export const subcategories = [
    { name: "claims", label: "Claims" },
    { name: "patient_validation", label: "Patient validation" },
    { name: "members", label: "Members" },
    { name: "spot", label: "Insurance verification", caption: '(Spot)' },

];

export const categories = [
    { name: 'uhc', label: 'UHC', group: 'UNITED HEALTHCARE', subcategories },
    { name: 'denta', label: 'DENTA', group: 'DentaQuest', subcategories },
    { name: 'mcna', label: 'MCNA', group: 'MCNA', subcategories },
    { name: 'tmhp', label: 'TMHP', group: 'TMHP', subcategories: subcategories.slice(0, 2) },
];

export const colorMap = (value: string) => {
    switch (value) {
        case 'yes':
            return 'green';
        case 'no':
            return 'red';
        case null:
            return 'yellow';
        case 'no members':
            return 'olive';
        default:
            return 'orange'
    }
}

export const dentaTableFields = [
    { name: 'uhc_claims', insurance: 'UHC Medicaid', scraper: 'Claims', caption: 'claims' },
    { name: 'uhc_members', caption: 'members' },
    { name: 'uhc_patient_validation', insurance: 'UHC Medicaid', scraper: 'PV', caption: 'patients' },
    { name: 'uhc_spot', caption: 'patients' },
    { name: 'denta_claims', caption: 'claims' },
    { name: 'denta_members', caption: 'members' },
    { name: 'denta_patient_validation', insurance: 'DentaQuest', scraper: 'PV', caption: 'patients' },
    { name: 'denta_spot', caption: 'patients' },
    { name: 'mcna_claims', caption: 'claims' },
    { name: 'mcna_members', caption: 'members' },
    { name: 'mcna_patient_validation', insurance: 'MCNA', scraper: 'PV', caption: 'patients' },
    { name: 'mcna_spot', caption: 'patients' },
    { name: 'tmhp_claims', caption: 'claims' },
    { name: 'tmhp_patient_validation', insurance: 'TMHP', scraper: 'PV', caption: 'patients' },
    { name: 'tmhp_spot', caption: 'patients' }
]


export const group: { [key: string]: { failed: number, total: number } } = {
    uhc: { failed: 0, total: 0 },
    denta: { failed: 0, total: 0 },
    mcna: { failed: 0, total: 0 },
    tmhp: { failed: 0, total: 0 }
}

export const groupCount = (scrapper: any, total: number, failed: number) => {
    switch (scrapper) {
        case 'uhc_patient_validation':
            group.uhc.failed += failed;
            group.uhc.total += total;
            break;
        case 'denta_patient_validation':
            group.denta.failed += failed;
            group.denta.total += total;
            break;
        case 'mcna_patient_validation':
            group.mcna.failed += failed;
            group.mcna.total += total;
            break;
        case 'tmhp_patient_validation':
            group.tmhp.failed += failed;
            group.tmhp.total += total;
            break;
        default:
            break;
    }

    return group;
}

export const dentalXChangeFields = [
    { name: 'uhc_dentalXChange', label: 'dentalXChange' },
    { name: 'denta_dentalXChange', label: 'dentalXChange' },
    { name: 'mcna_dentalXChange', label: 'dentalXChange' },
    { name: 'tmhp_dentalXChange', label: 'dentalXChange' },
    { name: 'pop_dentalXChange', label: 'dentalXChange' }

]

export const capitalizeFirstLetter = (word: string) => {
    if (!word) return '';
    return word.charAt(0).toUpperCase() + word.slice(1);
}

export const excludedStatuses = [
    'PT Assigned To Practice Active Insurance',
    'PT Scraped But Exception Thrown',
    'PT Does NOT Have Active Insurance',
    'PT Has Active Insurance But Not Assigned To Practice'
];

export const getStatus = (row: any) => {
    let status = <Icon name='question circle' color='yellow' />
    if (row.status === 'PT Assigned To Practice Active Insurance') {
        status = <Icon name='check circle' color='green' />
    } else if (row.status === 'PT Scraped But Exception Thrown') {
        status = <Icon name='check circle' color='blue' />
    } else if (row.status === 'PT Does NOT Have Active Insurance') {
        status = <Icon name='delete' color='red' />
    } else if (row.status === 'PT Has Active Insurance But Not Assigned To Practice') {
        status = <Icon name='font' color='red' />
    }
    return status
}
const crons = [
    { id: 1001, name: 'MCNA' },
    { id: 1002, name: 'TMHP' },
    { id: 1003, name: 'Denta' },
    { id: 1004, name: 'UHC' },

]
export const SpotReRunOptions = (runCron: Function, running: number[]) => {
    return <Grid centered divided columns={4}>
        {crons.map(({ id, name }, key) => <GridColumn key={key} textAlign='center'>
            <Header as='h4'> Insurance Status </Header>
            <Header as='h4'>{name}</Header>
            {running.includes(id) && (
                <>
                    <Icon color="grey" name="clock outline" />
                    <small>{name} cron was rerun</small>
                    <br />
                    <br />
                </>
            )}
            <Button secondary onClick={() => runCron(id)}>Re-run</Button>
        </GridColumn>)}
    </Grid>
}

export enum TABS {
    CRONS = 'crons',
    PATIENT_VALIDATION = 'patient validation',
    MYSQL = 'mySQL',
    SPOT_HEALTH ='spotHealth',
    OPEN_DENTAL_CONNECTIONS = 'openDentalConnections',
    APTOOD = 'aptood',
    XRAY_SERVICES = 'xray_services',
    DB_MIGRATIONS = 'DbMigrations'
}