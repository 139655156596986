import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Message, Icon } from 'semantic-ui-react';
import Template from "../../../components/Template";
import CronEmailTable from './CronEmailTable';
import HelpMark from "../../../components/HelpMark";
import CronSelect from './CronSelect';
import EmailListInput from './EmailListInput';
import * as cronEmailSettingsActions from "../../../redux/actions/cronEmailSettings";
import { useActions } from '../../../redux/actions';

interface CronOption {
    id: number;
    title: string;
}

interface CronEmailSettings {
    id: number;
    cronId: number;
    cronTitle: string;
    group: string;
    description: string;
    emailGroups: string[];
    lastRun?: string;
    status?: 'active' | 'inactive';
}

function CronsMailingSettings() {
    const [state, setState] = useState({
        cronSettings: [] as CronEmailSettings[],
        availableCrons: [] as CronOption[],
        isLoading: true,
        selected: {} as any,
        showModal: false,
        modalMode: 'add' as 'add' | 'edit',
        message: null as { positive?: boolean; negative?: boolean; content: string } | null
    });
    const cronEmailSettingsAction = useActions(cronEmailSettingsActions);
    const [deleteConfirm, setDeleteConfirm] = useState<{show: boolean, id: number | null}>({
        show: false,
        id: null
    });

    useEffect(() => {
        loadCronSettings();
        loadAvailableCrons();
    }, []);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (state.message) {
            timeoutId = setTimeout(() => {
                setState(prev => ({ ...prev, message: null }));
            }, 3000); // Message will disappear after 3 seconds
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [state.message]);

    const loadAvailableCrons = async () => {
        try {
            const resp = await cronEmailSettingsAction.getActiveCrons();
            setState(prev => ({ 
                ...prev, 
                availableCrons: resp || [] // Use API response, fallback to empty array
            }));
        } catch (error) {
            console.error('Error loading available crons:', error);
            setState(prev => ({ 
                ...prev, 
                availableCrons: [] 
            }));
        }
    };

    const loadCronSettings = async () => {
        try {
            const resp = await cronEmailSettingsAction.getCronEmailSettings();
            
            // Transform the API response to match our interface
            const formattedSettings = resp.map((item: any) => ({
                id: item.id,
                cronId: item.cronId,
                cronTitle: item.cron.title,
                group: item.group,
                description: item.description,
                emailGroups: item.emails.split(','), // Convert comma-separated string to array
                lastRun: item.cron.logs?.[0]?.createdAt, // Get the latest log date
                status: item.cron.status ? 'active' : 'inactive'
            }));

            setState(prev => ({ 
                ...prev, 
                cronSettings: formattedSettings,
                isLoading: false 
            }));
        } catch (error) {
            console.error('Error loading cron settings:', error);
            setState(prev => ({ 
                ...prev, 
                cronSettings: [], 
                isLoading: false 
            }));
        }
    };

    const handleSave = async () => {
        const { selected, modalMode } = state;

        // Validate required fields
        if (!selected.cronId || !selected.description || !selected.emailGroups?.length || !selected.group) {
            setState(prev => ({ 
                ...prev, 
                message: { 
                    negative: true, 
                    content: 'Please fill in all required fields' 
                }
            }));
            return;
        }

        try {
            setState(prev => ({ ...prev, isLoading: true }));

            const payload = {
                cronId: selected.cronId,
                emails: selected.emailGroups.join(','),
                group: selected.group,
                description: selected.description,
                ...(modalMode === 'edit' && { id: selected.id })
            };

            if (modalMode === 'edit') {
                await cronEmailSettingsAction.updateCronEmailSettings(payload);
            } else {
                await cronEmailSettingsAction.createCronEmailSettings(payload);
            }
            
            setState(prev => ({ 
                ...prev, 
                showModal: false, 
                selected: {},
                isLoading: false,
                message: { 
                    positive: true, 
                    content: `Successfully ${modalMode === 'edit' ? 'updated' : 'created'} cron email setting` 
                }
            }));

            await loadCronSettings();
        } catch (error) {
            console.error('Error saving cron email setting:', error);
            setState(prev => ({ 
                ...prev, 
                isLoading: false,
                message: { 
                    negative: true, 
                    content: `Failed to ${modalMode === 'edit' ? 'update' : 'create'} cron email setting` 
                }
            }));
        }
    };

    const handleEdit = (cron: CronEmailSettings) => {
        setState(prev => ({
            ...prev,
            selected: cron,
            showModal: true,
            modalMode: 'edit'
        }));
    };

    const handleCronSelect = (cronId: number | null) => {
        if (!cronId) return;
        
        const selectedCron = state.availableCrons.find(c => c.id === cronId);
        if (!selectedCron) return;

        setState(prev => ({
            ...prev,
            selected: {
                ...prev.selected,
                cronId: selectedCron.id,
                cronTitle: selectedCron.title
            }
        }));
    };

    const handleDelete = async (id: number) => {
        try {
            setState(prev => ({ ...prev, isLoading: true }));
            await cronEmailSettingsAction.deleteCronEmailSettings(id);
            setDeleteConfirm({ show: false, id: null });
            await loadCronSettings();
            setState(prev => ({ 
                ...prev, 
                message: { positive: true, content: 'Successfully deleted cron email setting' }
            }));
        } catch (error) {
            console.error('Error deleting cron email setting:', error);
            setState(prev => ({ 
                ...prev, 
                message: { negative: true, content: 'Failed to delete cron email setting' }
            }));
        } finally {
            setState(prev => ({ ...prev, isLoading: false }));
        }
    };

    return (
        <Template activeLink="crons-mailing-settings">
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left">
                        Cron Email Settings
                        <HelpMark pageId='cron-email-settings'/>
                    </h2>
                    <div className="float-right">
                        <Button 
                            primary
                            icon 
                            labelPosition='left'
                            onClick={() => setState(prev => ({ 
                                ...prev, 
                                showModal: true, 
                                modalMode: 'add',
                                selected: {}
                            }))}
                        >
                            <Icon name='add'/>
                            Add New Cron Email Setting
                        </Button>
                    </div>
                </div>
            </div>

            <div className="ui card">
                <div className="content">
                    <CronEmailTable 
                        data={state.cronSettings}
                        isLoading={state.isLoading}
                        onEdit={handleEdit}
                        onDelete={(id) => setDeleteConfirm({ show: true, id })}
                    />
                </div>
            </div>

            <Modal open={state.showModal} onClose={() => setState(prev => ({ ...prev, showModal: false }))}>
                <Modal.Header>
                    {state.modalMode === 'add' ? 'Add New Cron Email Setting' : 'Edit Cron Email Setting'}
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <label>Select Cron</label>
                            <CronSelect
                                value={state.selected.cronId}
                                onChange={handleCronSelect}
                                crons={state.availableCrons}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Group Name</label>
                            <Input 
                                placeholder='Enter group name (e.g., Billing Team)'
                                value={state.selected.group || ''}
                                onChange={(e, { value }) => setState(prev => ({
                                    ...prev,
                                    selected: { ...prev.selected, group: value }
                                }))}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Description</label>
                            <Input 
                                placeholder='Describe the purpose of this cron'
                                value={state.selected.description || ''}
                                onChange={(e, { value }) => setState(prev => ({
                                    ...prev,
                                    selected: { ...prev.selected, description: value }
                                }))}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Email Recipients</label>
                            <EmailListInput 
                                emails={state.selected.emailGroups || []}
                                onChange={(emails) => setState(prev => ({
                                    ...prev,
                                    selected: { ...prev.selected, emailGroups: emails }
                                }))}
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={() => setState(prev => ({ ...prev, showModal: false }))}>
                        Cancel
                    </Button>
                    <Button positive onClick={handleSave}>
                        Save
                    </Button>
                </Modal.Actions>
            </Modal>

            <Modal
                size='mini'
                open={deleteConfirm.show}
                onClose={() => setDeleteConfirm({ show: false, id: null })}
            >
                <Modal.Header>Delete Cron Email Setting</Modal.Header>
                <Modal.Content>
                    <p>Are you sure you want to delete this cron email setting?</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={() => setDeleteConfirm({ show: false, id: null })}>
                        No
                    </Button>
                    <Button positive onClick={() => deleteConfirm.id && handleDelete(deleteConfirm.id)}>
                        Yes
                    </Button>
                </Modal.Actions>
            </Modal>

            {state.message && (
                <Message
                    positive={state.message.positive}
                    negative={state.message.negative}
                    onDismiss={() => setState(prev => ({ ...prev, message: null }))}
                >
                    {state.message.content}
                </Message>
            )}
        </Template>
    );
}

export default CronsMailingSettings;