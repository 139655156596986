import React, { useState } from 'react';
import { Input, Button, List, Icon, Segment } from 'semantic-ui-react';
import './EmailListInput.css';

interface Props {
    emails: string[];
    onChange: (emails: string[]) => void;
}

function EmailListInput({ emails, onChange }: Props) {
    const [newEmail, setNewEmail] = useState('');
    const [error, setError] = useState('');

    const validateEmail = (email: string) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleAddEmail = () => {
        const trimmedEmail = newEmail.trim();
        
        if (!trimmedEmail) {
            setError('Email cannot be empty');
            return;
        }

        if (!validateEmail(trimmedEmail)) {
            setError('Invalid email format');
            return;
        }

        if (emails.includes(trimmedEmail)) {
            setError('Email already exists');
            return;
        }

        onChange([...emails, trimmedEmail]);
        setNewEmail('');
        setError('');
    };

    const handleRemoveEmail = (emailToRemove: string) => {
        onChange(emails.filter(email => email !== emailToRemove));
    };

    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleAddEmail();
        }
    };

    return (
        <div>
            <div className="email-input-container">
                <Input
                    fluid
                    placeholder='Enter email address'
                    value={newEmail}
                    onChange={(e) => {
                        setNewEmail(e.target.value);
                        setError('');
                    }}
                    onKeyPress={handleKeyPress}
                    error={!!error}
                    action={
                        <Button 
                            icon='add' 
                            content='Add'
                            onClick={handleAddEmail}
                            primary
                        />
                    }
                />
            </div>
            {error && <div className="email-error-message">{error}</div>}
            
            <Segment className="email-list-container">
                <List divided relaxed>
                    {emails.map((email, index) => (
                        <List.Item key={index} className="email-list-item">
                            <List.Content floated='right'>
                                <Button
                                    icon
                                    size='mini'
                                    negative
                                    onClick={() => handleRemoveEmail(email)}
                                >
                                    <Icon name='trash' />
                                </Button>
                            </List.Content>
                            <List.Icon name='mail' size='large' verticalAlign='middle' />
                            <List.Content verticalAlign='middle'>
                                {email}
                            </List.Content>
                        </List.Item>
                    ))}
                </List>
            </Segment>
        </div>
    );
}

export default EmailListInput; 