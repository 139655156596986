import { connect } from "react-redux";
import { User } from "../../../model/user";
import { useActions } from "../../../redux/actions";
import Template from "../../../components/Template";
import React, { useState, useEffect } from "react";
import * as awsHostedSitesActions from "../../../redux/actions/awsHostedSites";
import * as practiceActions from "../../../redux/actions/practice";
import { HostedSite, PracticeData } from "./interfaces";
import AddSiteModal from "./modals/AddSiteModal";
import { SitesDetails } from "../../../model/awsHostedSites";
import * as sitesActions from "../../../redux/actions/awsHostedSites";
import DeleteSiteModal from "./modals/DeleteSiteModal";
import {
    Icon,
} from "semantic-ui-react";

// Add this helper function at the top of the file, outside the component
const stripProtocol = (url: string): string => {
    return url.replace(/^(https?:\/\/)?(www\.)?/, '').replace(/\/$/, '');
};

const initialState: {
    lighthouseScoreList: Array<{}>,
    externalHostedList: Array<HostedSite>,
    cdpHostedList: Array<HostedSite>,
    practiceList: Array<PracticeData>,
    actualData: any[],
    practiceInformation: any[],
    rawHostedSiteList: HostedSite[],
    sortConfig: { key: string; direction: 'asc' | 'desc' | null }
} = {
    cdpHostedList: [],
    externalHostedList: [],
    lighthouseScoreList: [],
    practiceList: [],
    actualData: [],
    rawHostedSiteList: [],
    practiceInformation: [],
    sortConfig: { key: '', direction: null }
}

// Update the sorting function to handle both directions
const sortData = (data: HostedSite[], key: string, direction: 'asc' | 'desc'): HostedSite[] => {
    return [...data].sort((a, b) => {
        const aValue = (a as any)[key]?.toString().toLowerCase() || '';
        const bValue = (b as any)[key]?.toString().toLowerCase() || '';
        return direction === 'asc'
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
    });
};

interface Props {
    auth: User
}

function AWSWebsites(props: Props) {
    const practiceAction = useActions(practiceActions)
    const awsHostedSitesAction = useActions(awsHostedSitesActions)

    const [activeTab, setActiveTab] = useState('cdp')
    const [state, setState] = useState(initialState)

    const [showAddSites, setShowAddSites] = useState(false);
    const [showDeleteSite, setShowDeleteSite] = useState(false);
    const [showEditPopupModal, setShowEditPopupModal] = useState(false);
    const sitesAction = useActions(sitesActions)

    const [searchTerm, setSearchTerm] = useState('');
    const [rawPracticeList, setRawPracticeList] = useState<PracticeData[]>([])


    const showAddSitesModal = () => {
        setShowAddSites(!showAddSites);
    }

    const showDeleteSitesModal = () => {
        setShowDeleteSite(!showDeleteSite);
    }

    const saveSite = (payload: any) => {
        // Convert payload practices array to lowercase for comparison
        const newPractices = payload.practice.map((p: string) => p.toLowerCase());

        // Check if any of the selected practices already have a site
        const existingSiteForPractice = state.rawHostedSiteList.find(site => {
            // Split existing site's practices into array and convert to lowercase
            const sitePractices = site.practice.split(',').map(p => p.trim().toLowerCase());
            // Check if any of the new practices overlap with existing site's practices
            return newPractices.some((newPractice: any) => sitePractices.includes(newPractice));
        });

        if (existingSiteForPractice) {
            const conflictingPractice = existingSiteForPractice.practice.split(',')[0].trim();
            alert(`A website already exists for one or more selected practices. First conflict: ${conflictingPractice}`);
            return;
        }

        // Check if the site URL already exists
        const existingSiteWithUrl = state.rawHostedSiteList.find(
            site => stripProtocol(site.liveSite).toLowerCase() === stripProtocol(payload.liveSite).toLowerCase()
        );

        if (existingSiteWithUrl) {
            alert(`A website with URL ${payload.liveSite} already exists`);
            return;
        }

        payload.isNewSite = true;
        awsHostedSitesAction.saveSite(payload);
    }

    const CORP = { id: 0, practice: 'CORP', brand: 'CDP', status: 'Operating' };

    useEffect(() => {
        awsHostedSitesAction.getHostedSitesPractices().then((res: any) => {
            setRawPracticeList([CORP, ...res])
        });

        practiceAction.fetchWebsitesPracticesAlt().then((practiceList: PracticeData[]) => {

            // @ts-ignore
            practiceList.push(CORP);

            setState({
                ...state,
                practiceList
            })

            // @ts-ignore
            window.practiceList = practiceList;

            awsHostedSitesAction.getHostedSites().then((hostedSites: HostedSite[]) => {
                const removedDuplicates: HostedSite[] = hostedSites.reduce((accumulator: any, current: any) => {
                    if (!accumulator.find((item: any) => item.practice === current.practice)) {
                        accumulator.push(current)
                    }
                    return accumulator;
                }, [])


                const populatedHostedSites: HostedSite[] = removedDuplicates.map((site: HostedSite) => {
                    site.practice = site.practice.split(',')[0].trim()
                    site.rawPracticeList = site.practice.split(',').map((item) => item.trim())

                    const practiceInformation: undefined | PracticeData = practiceList.find(
                        (item: PracticeData) => {

                            const itemName = (item.practice || item.practiceName) || "Name Not Specified";
                            const siteName = site.practice || "Name Not Specified";

                            return itemName.trim().toLowerCase().includes(siteName.trim().toLowerCase())
                        }
                    );

                    site.status = practiceInformation?.status ?? "Not Set"
                    site.brand = practiceInformation ? practiceInformation.brand : "No Brand";

                    return site;
                })

                console.log({ populatedHostedSites })

                awsHostedSitesAction.getLighthouseScores().then((lighthouseResults: any) => {
                    setState({
                        ...state,
                        cdpHostedList: populatedHostedSites.filter((item: HostedSite) => item.contentManager.toLowerCase() === 'cdp'),
                        externalHostedList: populatedHostedSites.filter((item: HostedSite) => item.contentManager.toLowerCase() !== 'cdp'),
                        lighthouseScoreList: lighthouseResults,
                        rawHostedSiteList: populatedHostedSites
                    })
                })
            })
        })
    }, []);

    function switchTab(tab: string) {
        setActiveTab(tab);
    }

    const onSearchTermChanged = (term: string) => {
        setSearchTerm(term)

        const searchMatchingItems = (item: HostedSite) =>
            item?.brand?.toLowerCase().includes(term.toLowerCase()) ||
            item.practice.toLowerCase().includes(term.toLowerCase()) ||
            item.liveSite.toLowerCase().includes(term.toLowerCase());

        setState({
            ...state,
            cdpHostedList: state.rawHostedSiteList.filter((item: HostedSite) => searchMatchingItems(item) && item.contentManager.toLowerCase() === 'cdp'),
            externalHostedList: state.rawHostedSiteList.filter((item: HostedSite) => searchMatchingItems(item) && item.contentManager.toLowerCase() !== 'cdp'),
        })
    }

    const handleSort = (key: string) => {
        const newState = { ...state };
        const direction = state.sortConfig.key === key && state.sortConfig.direction === 'asc' ? 'desc' : 'asc';
        newState.sortConfig = { key, direction };

        newState.cdpHostedList = sortData(state.cdpHostedList, key, direction);
        newState.externalHostedList = sortData(state.externalHostedList, key, direction);

        setState(newState);
    };

    const getSortIcon = (columnKey: string) => {
        if (state.sortConfig.key !== columnKey) return null;

        return state.sortConfig.direction === 'asc' ? (
            <Icon name="sort up" />
        ) : (
            <Icon name="sort down" />
        );
    };

    const tableHeaders = (
        <tr>
            {[
                { key: 'brand', label: 'Brand' },
                { key: 'practice', label: 'Practice Name' },
                { key: 'liveSite', label: 'Domain Name' },
                { key: 'status', label: 'Website Status' },
                { key: 'status', label: 'Operating Status' },
                { key: null, label: 'Actions' }
            ].map((column) => (
                column.key ? (
                    <th
                        key={column.key}
                        onClick={() => handleSort(column.key)}
                        style={{ cursor: 'pointer' }}
                    >
                        {column.label} {getSortIcon(column.key)}
                    </th>
                ) : (
                    <th key={column.label}>{column.label}</th>
                )
            ))}
        </tr>
    );


    // Function to normalize strings by removing extra spaces and converting to lowercase
    const normalizeString = (str: string): string => {
        return str.toLowerCase().replace(/\s+/g, ' ').trim();
    };

    // Function to calculate Levenshtein distance between two strings
    const levenshteinDistance = (str1: string, str2: string): number => {
        const matrix: number[][] = [];

        for (let i = 0; i <= str1.length; i++) {
            matrix[i] = [i];
        }

        for (let j = 0; j <= str2.length; j++) {
            matrix[0][j] = j;
        }

        for (let i = 1; i <= str1.length; i++) {
            for (let j = 1; j <= str2.length; j++) {
                const cost = str1[i - 1] === str2[j - 1] ? 0 : 1;
                matrix[i][j] = Math.min(
                    matrix[i - 1][j] + 1,      // deletion
                    matrix[i][j - 1] + 1,      // insertion
                    matrix[i - 1][j - 1] + cost // substitution
                );
            }
        }

        return matrix[str1.length][str2.length];
    };

    // Function to check if one set of words is a subset of another
    const isWordSubset = (subsetWords: string[], targetWords: string[]): boolean => {
        const targetWordSet = new Set(targetWords);
        return subsetWords.every(word => targetWordSet.has(word));
    };

    // Create a cache to store results of previous searches
    const practiceCache = new Map<string, any>();

    const getPracticeFromName = (practiceName: string) => {
        const normalizedSearchTerm = normalizeString(practiceName);

        // Check if the result is already in the cache
        if (practiceCache.has(normalizedSearchTerm)) {
            console.log(`Returning cached result for: ${normalizedSearchTerm}`);
            return practiceCache.get(normalizedSearchTerm);
        }

        const searchWords = normalizedSearchTerm.split(' ');
        const maxDistance = Math.floor(normalizedSearchTerm.length * 0.3); // Allow up to 30% difference

        let bestMatch = null;
        let bestDistance = Infinity;

        for (const item of rawPracticeList) {
            // Check both practice and practiceName fields if they exist
            const itemNames = [
                item.practice,
                item.practiceName
            ].filter(Boolean);

            for (let itemName of itemNames) {
                itemName = normalizeString(itemName);
                const itemWords = itemName.split(' ');

                // First check if searchWords is a subset of itemWords or vice versa
                if (isWordSubset(searchWords, itemWords) || isWordSubset(itemWords, searchWords)) {
                    // Cache the result before returning
                    practiceCache.set(normalizedSearchTerm, item);
                    return item;
                }

                // If no subset match, fall back to Levenshtein distance
                const distance = levenshteinDistance(normalizedSearchTerm, itemName);
                if (distance < bestDistance && distance <= maxDistance) {
                    bestDistance = distance;
                    bestMatch = item;
                }
            }
        }

        // Cache the best match (even if it's null) before returning
        practiceCache.set(normalizedSearchTerm, bestMatch);
        return bestMatch;
    };

    const getOperatingStatus = (practiceName: string) => {
        const practice: any = getPracticeFromName(practiceName);
        return practice?.status || 'Not Set';
    }

    const getBrand = (practiceName: string) => {
        const practice: any = getPracticeFromName(practiceName);
        return practice?.brand || 'No Brand';
    }

    const getPracticeFormattedName = (practiceName: string) => {
        const practice: any = getPracticeFromName(practiceName);
        return practice?.practice || practiceName;
    }

    return (
        <Template activeLink='aws websites'>
            {showAddSites &&
                <AddSiteModal id={'modal'} onSubmitHandler={saveSite} showAddSitesModalHandler={showAddSitesModal} />}
            {showDeleteSite && <DeleteSiteModal id={'delete-modal'} hideDeleteSiteModal={showDeleteSitesModal} />}

            <main className='aws-websites-root poppins-regular'>
                {/*<div className="websites-header">
                    <div className="card">
                        <div className="card-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                 stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="m6.115 5.19.319 1.913A6 6 0 0 0 8.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 0 0 2.288-4.042 1.087 1.087 0 0 0-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 0 1-.98-.314l-.295-.295a1.125 1.125 0 0 1 0-1.591l.13-.132a1.125 1.125 0 0 1 1.3-.21l.603.302a.809.809 0 0 0 1.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 0 0 1.528-1.732l.146-.292M6.115 5.19A9 9 0 1 0 17.18 4.64M6.115 5.19A8.965 8.965 0 0 1 12 3c1.929 0 3.716.607 5.18 1.64"/>
                            </svg>
                        </div>
                        <div className="card-content">
                            <h3 className="card-title">Online Websites</h3>

                            <h2 className="card-number">
                                {state.rawHostedSiteList.length}
                            </h2>
                        </div>
                    </div>

                    <div className="card" onClick={() => setActiveTab('external')}>
                        <div className="card-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M2.25 15a4.5 4.5 0 0 0 4.5 4.5H18a3.75 3.75 0 0 0 1.332-7.257 3 3 0 0 0-3.758-3.848 5.25 5.25 0 0 0-10.233 2.33A4.502 4.502 0 0 0 2.25 15Z"/>
                            </svg>
                        </div>
                        <div className="card-content">
                            <h3 className="card-title">External Hosted Websites</h3>

                            <h2 className="card-number">
                                {state.rawHostedSiteList.filter(i => i.contentManager.toLocaleLowerCase() !== 'cdp').length}
                            </h2>
                        </div>
                    </div>

                    <div className="card" onClick={() => setActiveTab('cdp')}>
                        <div className="card-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z"/>
                            </svg>
                        </div>
                        <div className="card-content">
                            <h3 className="card-title">CDP Hosted Sites</h3>

                            <h2 className="card-number">
                            {state.rawHostedSiteList.filter(i => i.contentManager.toLocaleLowerCase() === 'cdp').length}
                            </h2>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"/>
                            </svg>
                        </div>
                        <div className="card-content">
                            <h3 className="card-title">Reported Incidents</h3>

                            <h2 className="card-number">
                                8
                                <span className="down">
                               <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                    stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round"
        d="M2.25 6 9 12.75l4.286-4.286a11.948 11.948 0 0 1 4.306 6.43l.776 2.898m0 0 3.182-5.511m-3.182 5.51-5.511-3.181"/>
</svg>
                            </span></h2>
                        </div>
                    </div>
                </div>

                */}

                <div className="websites-data-tables-section">

                    <h1 className={'poppins-black'}>
                        Websites List
                    </h1>

                    <div className="table-top-actions">
                        <a href={'/aws/websites/legacy'}>Use Legacy Dashboard</a>

                        {/*<a href="/aws/websites/reports">Reports</a> */}

                        <a className={'highlighted'} onClick={(e) => showAddSitesModal()} href="javascript:void(0)">Add
                            New Website</a>
                    </div>


                    <div className="table-selector">
                        <ul className="table-selector-list">
                            <li className={`${activeTab === 'cdp' ? 'active' : ''}`}>
                                <a onClick={e => switchTab('cdp')} href="javascript:void(0)">CDP Hosted Sites</a>
                            </li>
                            <li className={`${activeTab === 'external' ? 'active' : ''}`}>
                                <a onClick={(e) => switchTab('external')} href="javascript:void(0)">External Hosted
                                    Websites</a>
                            </li>
                        </ul>
                    </div>

                    <div className="table-search">
                        <input onChange={(e) => onSearchTermChanged(e.target.value)} type="text" className="search"
                            placeholder="Enter search term..." />
                    </div>


                    {activeTab === 'cdp' && <div className="cdp-table website-stats-table">
                        <table className="table table-striped mt-4">
                            <thead>
                                {tableHeaders}
                            </thead>

                            <tbody>
                                {state.cdpHostedList.map((item, i) => (
                                    <tr key={i} className="">
                                        <td className={'poppins-bold'}>{getBrand(item.practice)}</td>
                                        <td>{getPracticeFormattedName(item.practice)}{item.rawPracticeList.length > 1 && <span
                                            title="View all practices ">+ {item.rawPracticeList.length - 1} more</span>}</td>
                                        <td><a target="_blank" rel="noreferrer noopener"
                                            href={`http://` + item.liveSite}>{stripProtocol(item.liveSite)}</a></td>
                                        <td className={'table-status-online status'}
                                            title={"This is website is currently online"}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                    d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                            </svg>
                                        </td>
                                        <td>
                                            {getOperatingStatus(item.practice)}
                                        </td>
                                        <td className={'actions'}>
                                            <a href={`/aws/websites/view/${item.id}`}>
                                                Manage Website
                                            </a>
                                            <a className={'is-danger'} href='javascript:void(0);'
                                                onClick={showDeleteSitesModal}>
                                                Delete Website
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}

                    {activeTab === 'external' && <div className="external-table website-stats-table">
                        <table className="table table-striped mt-4">
                            <thead>
                                {tableHeaders}
                            </thead>

                            <tbody>
                                {state.externalHostedList.map((item, i) => (
                                    <tr key={i} className="">
                                    <td className={'poppins-bold'}>{getBrand(item.practice)}</td>
                                    <td>{item.practice}{item.rawPracticeList.length > 1 && <span
                                        title="View all practices ">+ {item.rawPracticeList.length - 1} more</span>}</td>
                                    <td><a target="_blank" rel="noreferrer noopener"
                                        href={`http://` + item.liveSite}>{stripProtocol(item.liveSite)}</a></td>
                                    <td className={'table-status-online status'}
                                        title={"This is website is currently online"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                        </svg>
                                    </td>
                                    <td>
                                        {getOperatingStatus(item.practice)}
                                    </td>
                                    <td className={'actions'}>
                                        <a href={`/aws/websites/view/${item.id}`}>
                                            Manage Website
                                        </a>
                                        <a className={'is-danger'} href='javascript:void(0);'
                                            onClick={showDeleteSitesModal}>
                                            Delete Website
                                        </a>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                </div>
            </main>
        </Template>
    )
}

function mapStateToProps(state: any) {
    return {
        auth: state.auth,
    };
}

export default connect(mapStateToProps)(AWSWebsites)
