export enum ActionType {
    FETCH_CLINICAL_SCHEDULE_COUNT,
    MESSAGE,
    LOADER,
    ALERT,
    LOAD_SESSION,
    LOGIN_BEGIN,
    LOGIN_SUCCESS,
    LOGOUT,
    SIDEBAR_OPEN,
    FETCH_PRACTICES,
    FETCH_ALL_PRACTICES,
    FETCH_PRACTICES_ADMIN_SUCCESS,
    UPDATE_PRACTICE_ADMIN_SUCCESS,
    FETCH_PRACTICE,
    FETCH_REGIONS,
    FETCH_REGIONMANAGERS,
    FETCH_PMD_SCHEDULES,
    LOAD_DENTAL_PARTNERS,
    LOAD_DENTAL_PARTNERS_SUCCESS,
    FETCH_REPORT,
    FETCH_REPORT_PER_PRACTICE,
    FETCH_SCHEDULE,
    FETCH_BUDGET,
    FETCH_BUDGET_PER_PRACTICE,
    SAVE_DENTAL_PARTNERS_USAGE,
    SAVE_DENTAL_PARTNERS_USAGE_SUCCESS,
    SEND_REPORT,
    FETCH_CLINICAL_SCHEDULE,
    ADJUSTMENT_SAVE_SUCCESS,
    ACKNOLEGDEMENT,
    FETCH_CALL_CENTER_PRACTICES,
    FETCH_FIVE9_LIST,
    FETCH_FIVE9_PRACTICE_LIST,
    FIVE9_SAVE_SUCCESS,
    FETCH_REPORT_LAST_MONTHS,
    PATIENTS_VALIDATION,
    FETCH_PRACTICES_DB,
    LOAD_MDM_INFO_SUCCESS,
    SEND_FEEDBACK,
    SAVE_USAGE,
    FETCH_OPEN_SURGERY,
    LOAD_MDM_DATASOURCE,
    LOAD_MDM_PAGES,
    LOAD_MDM_REPORTS,
    LOAD_MDM_HELP_REPORT_SUCCESS,
    FETCH_APT,
    PATIENT_VALIDATION_DEFAULT_PRACTICES,
    CARRIER_IDENTITY,
    SAVE_USAGE_PTV,
    UPDATE_OS_BUDGET,
    FETCH_PATIENT_VALIDATION_REPORT,
    MDHM_CONFIRMATION,
    GET_HELP_MARK,
    DEFAULT_PRACTICES,
    MDHM_WRITE_OFF_REPORT,
    MDHM_WRITE_OFF_REPORT_DETAILS,
    PMD_DEFAULT_PRACTICES,
    PMD_MCO_NPW_LIST,
    PMD_MCO_RCN_LIST,
    PMD_TASKS,
    PMD_BILLER,
    FETCH_PATIENT_VALIDATION_ERROR_REPORT,
    PMD_SCHEDULE,
    XRAY_STATUS,
    LOAD_SPORE_TABLE_RESULT,
    LOAD_SPORE_USER_PRACTICES,
    LOAD_SPORE_NOTIFICATION_OMRM,
    LOAD_SPORE_NOTIFICATION_TABLE,
    SPORE_NOTIFICATION_SAVE_STATES,
    LOAD_SPORENOTIFICATION_USER_PRACTICES,
    MDHM_WRITE_OFF_D_REPORT,
    SAVE_USAGE_WO,
    LEADS_REPORT,
    PMD_SCHEDULE_COORDINATOR,
    PMD_SCHEDULE_COORDINATOR_WTX,
    PMD_SCHEDULE_COORDINATOR_APT,
    PMB_PRIORITY,
    PMD_SCHEDULE_TODAY_30_DAY_APTS,
    PMD_SCHEDULE_TODAY_30_DAY_COMPLETED,
    PMD_SCHEDULE_TODAY_30_DAY_PLAN_SCHEDULED_APTS,
    PMD_SCHEDULE_TODAY_SCH_APT_TODAY,
    PMD_SCHEDULE_TODAY_PT_SEEN_TODAY,
    PMD_TODAYS_PRODUCTION,
    FETCH_BUDGET_PERIODICAL,
    PMD_SCHEDULE_CHART_APTFILL_DAYAPT,
    PMD_PROGRESSBAR,
    INCREMENT,
    LOAD_EDUCATOR_NOTIFICATION,
    LOAD_EDUCATOR_NOTIFICATION_SAVE,
    DECREMENT,
    LOAD_API_LIST,
    PMD_TODAYS_GOAL_DETAIL,
    LWD_DATE,
    PMD_LWD_GOAL_DETAIL,
    PMD_LWD_PTCOUNT_DETAIL,
    PMD_SCHEDULE_TODAY_GOAL_ALL,
    INCREMENT_TODAY_GOAL_ALL,
    FETCH_REPORT_TODAY_GOAL_ALL,
    PMD_TODAY_GOAL_ALL,
    TODAY_GOAL_INCREMENT,
    TODAY_GOAL_DECREMENT,
    FETCH_SHIFT,
    FETCH_UDA_INFO_PRACTICES,
    LOAD_CRONS_LIST,
    LOAD_RAMP_THRESHOLD_DEFAULTS,
    LOAD_DR_APP_PERMISSIONS_MATRIX,
    UPDATE_PRACTICES_OPTIONS,
    FETCH_ORTHO_PCR,
    LOAD_ORAL_BUDGET,
    LOAD_PCR_BUDGET,
    FETCH_ORTHO_PCR_YTD,
    FETCH_ORTHO_PCR_MTD,
    FETCH_ORTHO_PCR_CODES,
    FETCH_ORTHO_PCR_CODES_UPDATE,
    FETCH_ORTHO_PCR_UDR_USER_DATA,
    FETCH_ORTHO_PCR_UDR_ORTHO_YTD_DATA,
    FETCH_ORTHO_PCR_UDR_ORTHO_MTD_DATA,
    FETCH_PROCEDURE_CODE_CHANGE_SUCCESS,
    FETCH_PROCEDURE_CODE_CHANGE_FAILURE,
    LOAD_PROCEDURE_CODE_GROUP_SUCCESS,
    LOAD_PROCEDURE_CODE_GROUP_FAILURE,
    UPDATE_PROCEDURE_CODE_CHANGER_SUCCESS,
    UPDATE_PROCEDURE_CODE_CHANGER_FAILURE,
    IMPORT_PROCEDURE_CODE_CHANGE_SUCCESS,
    IMPORT_PROCEDURE_CODE_CHANGE_FAILURE,
    CREATE_PROCEDURE_CODE_CHANGE_SUCCESS,
    CREATE_PROCEDURE_CODE_CHANGE_FAILURE,
    LOAD_PROCEDURE_CODE_GROUP_OPTIONS,
    LOAD_FAIL,
    CREATE_PROCEDURE_CODE_GROUP,
    DELETE_PROCEDURE_CODE_CHANGER_SUCCESS,
    LOADER_MTD,
    LOADER_YTD,
    FETCH_MOUNT_DATE,
    LOADER_STATUS_CODES,
    PT_TODAY_GOAL_ALL,
    UPDATE_REGION_OPTIONS,
    UPDATE_BRANDS_OPTIONS,
    FETCH_PT,
    FETCH_ORTHO_PRACTICES,
    FETCH_REFERRAL_PRACTICES,
    FETCH_REPORT_NEW_DATA,
    FETCH_ADJUSTMENT_CODES_SUCCESS,
    UPDATE_ADJUSTMENT_CODES_SUCCESS,
    DELETE_ADJUSTMENT_CODE_SUCCESS,
    CREATE_ADJUSTMENT_CODES_SUCCESS,
    CREATE_WRITE_OFF_LIST_SUCCESS,
    DELETE_WRITE_OFF_LIST_SUCCESS,
    FETCH_WRITE_OFF_LIST_SUCCESS,
    UPDATE_WRITE_OFF_LIST_SUCCESS,
    CREATE_GOX_PROCEDURE_CODES_SUCCESS,
    FETCH_GOX_PROCEDURE_CODES_SUCCESS,
    UPDATE_GOX_PROCEDURE_CODES_SUCCESS,
    FETCH_ORTHO_PCR_YTD_KEYS,
    FETCH_ORTHO_PCR_MTD_KEYS,
    CREATE_BRACKET_SYSTEM,
    FETCH_PATIENT_LOGS_REPORT,
    LOAD_BRACKET_SYSTEM,
    DELETE_BRACKET_SYSTEM,
    SAVE_APP_USAGE,
    SAVE_APP_USAGE_SUCCESS,
    FETCH_PS_BOARD_INFO,
    STORE_FILTER_INFO,
    FETCH_EMPLOYEE_USAGE_REPORT_SUCCESS,
    FETCH_EMPLOYEE_USAGE_REPORT_FAILURE,
    FETCH_UNIVERSAL_USAGE_REPORT_SUCCESS,
    FETCH_UNIVERSAL_USAGE_REPORT_FAILURE,
    RERUN_PATIENT_CARE_REPORT,
    SAVE_SAGE_ACCOUNT_SCUCCESS,
    FETCH_SAGE_ACCOUNT_SCUCCESS,
    UPDATE_SAGE_ACCOUNT_SCUCCESS,
    SAVE_IMPLANT_COST_SUCCESS,
    FETCH_IMPLANT_COST_SUCCESS,
    UPDATE_IMPLANT_COST_SUCCESS,
    PRESERVE_REPORT_TIME_NAME,
    PRESERVE_REPORT_DATA,
    FETCH_AWS_HOSTED_SITES,
    FETCH_HOSTED_SITES,
    FETCH_LIGHTHOUSE_SCORES,
    CREATE_HOSTED_SITE,
    DELETE_HOSTED_SITE,
    FETCH_FINANCIAL_NOTE_CATEGORY_SUCCESS,
    UPDATE_FINANCIAL_NOTE_CATEGORY_SUCCESS,
    SAVE_FINANCIAL_NOTE_CATEGORY_SUCCESS,
    PROVIDER_ACCOUNTS,
    LOAD_LAMBDAS_LIST,
    LOAD_LAMBDAS_LIST_FROM_AWS,
    FETCH_PMDCOLLECTIONS,
    FETCH_PMDWRITEOFFS,
    FETCH_PMDADJUSTMENTS,
    FETCH_PMD_NON_CONFIRMED_APPOINTMENTS,
    LOAD_DOWNTIME_TRACKER_LIST,
    SAVE_SPOT_USAGE,
    CREATE_REGION_SUCCESS,
    CREATE_REGION_FAIL,
    UPDATE_REGION_SUCCESS,
    UPDATE_REGION_FAIL,
    DELETE_REGION_SUCCESS,
    DELETE_REGION_FAIL,
    GET_REGIONS_SUCCESS,
    GET_REGIONS_FAIL,
    DELETE_PROVIDER_SUCCESS,
    SAVE_PROVIDER,
    PMD_S3_DATA,
    LOAD_UNSCHEDULED_TREATMENT,
    LOAD_SPOT_PATIENTS,
    LOAD_SPOT_PATIENT_RETENTION,
    FETCH_WEBSITES_PRACTICE_LIST,
    FETCH_WEBSITE_PRACTICE_LIST_ALT,
    LOAD_SPOT_WORK_REPORT,
    FETCH_OPEN_DENTAL_USAGE_REPORT_SUCCESS,
    HEALTH_STATUS_ADD_TO_COUNT,
    HEALTH_STATUS_PATIENT_VALIDATION,
    GET_TED_ENTRIES,
    SAVE_TED_ENTRY,
    UPDATE_TED_ENTRY,
    DELETE_TED_ENTRY,
    GET_PROVIDER_OPTIONS,
    HEALTH_FAILS
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}
