import {ActionType} from "../../model/actionTypes";
import {errorHandler} from "./auth";
export const getSiteURLs = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.get("aws/websites");
        dispatch({ type: ActionType.FETCH_AWS_HOSTED_SITES, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    }catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}

export const getSingleWebsiteDetails = (websiteId: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.get(`aws/websites/${websiteId}`);
        dispatch({ type: ActionType.FETCH_HOSTED_SITES, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    }catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}

export const getHostedSites = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.get("aws/websites/hosted");
        dispatch({ type: ActionType.FETCH_HOSTED_SITES, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    }catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}

export const getHostedSitesPractices = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.get("websites/getPracticeList");
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    }catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}

export const getLighthouseScores = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.get(`aws/websites/lighthouse-scores`);
        dispatch({ type: ActionType.FETCH_LIGHTHOUSE_SCORES, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    }catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}

export const saveSite = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.post(`aws/websites/save`, payload);
        dispatch({ type: ActionType.CREATE_HOSTED_SITE, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });

        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}

export const deleteSite = (id: number) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    const payload = {id: id}
    try {
        const resp =  await api.post(`aws/websites/delete`, payload);
        dispatch({ type: ActionType.DELETE_HOSTED_SITE, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });

        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}